import { FC, useState } from 'react'
import MarketCard from './sections/MarketCard'
import './Marketplace.scss'
import { investments } from '../../data/marketplace/investments'
import PageHero from '../shared/PageHero'

const Marketplace: FC = () => {
  const [activeTab, setActiveTab] = useState(0)

  const marketTabs: Array<string> = [
    'All',
    'Shopping Malls',
    'Hotels',
    'Luxury Yachts',
  ]

  return (
    <div className="container mx-auto mb-5 pb-4 justify-center text-center">
      <PageHero title="Marketplace" narrow bgClassName="bg-team">
        <p className="leading-relaxed">
          Investments - from 500 € or trading from 500 digital shares
        </p>
      </PageHero>

      <div className="container bg-white marketplace mt-5">
        <nav className="flex flex-col justify-center sm:flex-row">
          {marketTabs.map((el, index) => (
            <button
              key={el}
              className={`tab-text ${activeTab === index && 'tab-active'}`}
              onClick={() => setActiveTab(index)}
              type="button"
            >
              {el}
            </button>
          ))}
        </nav>
      </div>
      <div className="container bg-white marketplace mt-5">
        {marketTabs.map(
          (market, marketIndex) =>
            activeTab === Number(marketIndex) && (
              <div
                key={market}
                className="card-container grid grid-cols-4 gap-12"
              >
                {investments
                  .filter((el, index) => {
                    if (activeTab === 0) return true
                    return el.category === Number(activeTab)
                  })
                  .map((el, index) => (
                    <MarketCard
                      title={el.title}
                      key={el.title}
                      tabIndex={index}
                      route={el.slug}
                      imgSrc={el.images[0]}
                      state={el.state}
                      progress={el.progress}
                      ppDS={el.ppDS}
                      noDS={el.noDS}
                      finVolume={el.finVolume}
                      pofAssets={el.pofAssets}
                    >
                      {el.body.split(' ').slice(0, 25).join(' ')}
                    </MarketCard>
                  ))}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default Marketplace
