import { FC, ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'nuka-carousel'

interface HomeCarouselProps {
  children: ReactElement[] | HTMLElement[]
  slidesToShow?: number
  hasDots?: boolean
}

const HomeCarousel: FC<HomeCarouselProps> = ({
  children,
  slidesToShow = 3,
  hasDots = false,
}): ReactElement => {
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <>
      <button
        onClick={() => (slideIndex > 0 ? setSlideIndex(slideIndex - 1) : null)}
        type="button"
      >
        <FontAwesomeIcon
          className="left-navigation"
          icon={faAngleLeft}
          size="2x"
        />
      </button>
      <Carousel
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        cellSpacing={50}
        slideIndex={slideIndex}
        afterSlide={index => setSlideIndex(index)}
        scrollMode="page"
        heightMode="max"
        withoutControls={!hasDots}
        renderCenterLeftControls={({ currentSlide }) => ''}
        renderCenterRightControls={({ currentSlide }) => ''}
        defaultControlsConfig={{
          nextButtonText: 'Custom Next',
          prevButtonText: 'Custom Prev',
          pagingDotsContainerClassName: 'dots-container',
          pagingDotsClassName: 'dot',
        }}
      >
        {children}
      </Carousel>
      <button
        onClick={() => (slideIndex < 10 ? setSlideIndex(slideIndex + 1) : null)}
        type="button"
      >
        <FontAwesomeIcon
          className="right-navigation"
          icon={faAngleRight}
          size="2x"
        />
      </button>
    </>
  )
}

export default HomeCarousel
