import { ResponsivePie } from '@nivo/pie'

const data = [
  {
    id: 'Euro',
    label: 'Euro',
    value: 30,
    color: 'hsl(225, 70%, 50%)',
  },
  {
    id: 'BKEUR',
    label: 'BKEUR',
    value: 25,
    color: 'hsl(4, 70%, 50%)',
  },
  {
    id: 'Bitcoin',
    label: 'Bitcoin',
    value: 19,
    color: 'hsl(38, 70%, 50%)',
  },
  {
    id: 'Ethereum',
    label: 'Ethereum',
    value: 5,
    color: 'hsl(94, 70%, 50%)',
  },
  {
    id: 'USDC',
    label: 'USDC',
    value: 21,
    color: 'hsl(346, 70%, 50%)',
  },
]

const AssetsChart = () => (
  <ResponsivePie
    data={data}
    margin={{ top: 0, right: 150, bottom: 0, left: 10 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={1}
    valueFormat={val => `${val}%`}
    activeOuterRadiusOffset={8}
    enableArcLinkLabels={false}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.2]],
    }}
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 140,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        itemTextColor: '#000',
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
)

export default AssetsChart
