import { FC, ReactElement } from 'react'

interface KYCLevelCardProps {
  name: string
  children: string | ReactElement
}

const KYCLevelCard: FC<KYCLevelCardProps> = ({
  name,
  children,
}): ReactElement => {
  return (
    <li className="flex justify-between">
      <span className="text-gray-700">{name}: </span>
      <span>{children}</span>
    </li>
  )
}

export default KYCLevelCard
