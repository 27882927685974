import { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useUserValue } from '../../contexts/UserContext'

const RedirectLogged: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useUserValue()

  if (isAuthenticated) return <Navigate to="/" replace />
  return children
}

export default RedirectLogged
