/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, ReactElement, useEffect, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import OfferSteps from './OfferSteps'
import MarketCard from '../sections/MarketCardHorizontal'
import { investments as tempData } from '../../../data/marketplace/investments'
import { useUserValue } from '../../../contexts/UserContext'
import { postNewInvestment } from '../../../data/connections'

const Offer: FC = (): ReactElement => {
  const [currentStep, setCurrentStep] = useState(1)
  const [purchaseAmount, setPurchaseAmount] = useState<any>(0)
  const params = useParams()
  const [product, setProduct] = useState<any>(null)

  useEffect(() => {
    const searchResult = tempData.find(el => el.slug === params.slug)
    if (searchResult) setProduct(searchResult)
  }, [params.slug])

  return (
    <div className="body-font space-y-10 mb-20">
      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <OfferSteps currentStep={currentStep} />
      </div>
      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        {product
          ? {
              1: (
                <Step1
                  setCurrentStep={setCurrentStep}
                  product={product}
                  setPurchaseAmount={setPurchaseAmount}
                  purchaseAmount={purchaseAmount}
                />
              ),
              2: <Step2 setCurrentStep={setCurrentStep} />,
              3: (
                <Step3
                  setCurrentStep={setCurrentStep}
                  purchaseAmount={purchaseAmount}
                />
              ),
              4: <Step4 setCurrentStep={setCurrentStep} />,
            }[currentStep]
          : 'loading'}
      </div>
      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <button
          className={`bg-orange-600 text-gray-100 p-2 ${
            currentStep === 1 ? 'hidden' : 'çevrimdışı'
          }`}
          type="button"
          onClick={() => setCurrentStep(1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

const Step1: FC<any> = ({
  setCurrentStep,
  product,
  purchaseAmount,
  setPurchaseAmount,
}) => {
  return (
    <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
      <div className="w-full sm:max-w-3xl p-5 mx-auto">
        <form>
          <MarketCard
            title={product.title}
            key={product.title}
            tabIndex={0}
            route={product.slug}
            imgSrc={product.images[0]}
            state={product.state}
            progress={product.progress}
            ppDS={product.ppDS}
            noDS={product.noDS}
            finVolume={product.finVolume}
            pofAssets={product.pofAssets}
          />
          {/* Amount */}
          <div className="mb-4">
            <label className="block font-bold">
              How many digital shares do you want to invest in?
            </label>
            <input
              type="text"
              onChange={e => setPurchaseAmount(Number(e.target.value))}
              placeholder="Min value 500€"
              className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="mt-6">
            <button
              type="button"
              className="w-full inline-flex 
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
              onClick={() =>
                purchaseAmount > 499
                  ? setCurrentStep(2)
                  : // eslint-disable-next-line no-alert
                    alert('Minimum 500€ deposit')
              }
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const Step2: FC<any> = ({ setCurrentStep }) => {
  return (
    <div className="w-full sm:justify-center items-center sm:pt-0">
      <div className="w-1/2 p-5 mx-auto">
        <form className="grid grid-cols-2 gap-8">
          {/* Job */}
          <div className="mb-4">
            <label className="block font-bold">Current Job</label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Job</option>
              <option value="1">Asset Manager</option>
              <option value="2">Financial Advisor</option>
              <option value="3">Financial Broker</option>
              <option value="4">Investment Banker</option>
              <option value="5">Auditors</option>
              <option value="6">Bank Clerk</option>
              <option value="7">Managerial Function Controlling</option>
              <option value="8">Managerial Function Finance Department</option>
              <option value="9">Tax Consultant</option>
              <option value="10">Employee</option>
              <option value="11">Artisan</option>
              <option value="12">Other professions outside of finance</option>
            </select>
          </div>
          {/* Education */}
          <div className="mb-4">
            <label className="block font-bold">
              What is your highest level of education?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Education Level</option>
              <option value="1">Doctorate / Habilitation</option>
              <option value="2">Academic degree</option>
              <option value="3">Commercial training</option>
              <option value="4">
                Technical college or university entrance qualification
              </option>
              <option value="5">
                Secondary / Elementary / Junior high school diploma
              </option>
              <option value="6">Other vocational training</option>
              <option value="7">None of the degrees mentioned</option>
            </select>
          </div>
          {/* Knowledge */}
          <div className="mb-4">
            <label className="block font-bold">
              Do you have any knowledge of dealing with investment funds?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Answer</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block font-bold">
              Do you have any knowledge of dealing with bonds?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Answer</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block font-bold">
              Do you have any knowledge of dealing with shares?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Answer</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block font-bold">
              Do you have any knowledge of how to deal with participation
              rights?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Answer</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block font-bold">
              Do you have any knowledge of dealing with subordinated /
              participatory loans?
            </label>

            <select
              className="form-select appearance-none
      block
      w-full
      py-2 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      mt-1 
      focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50
      rounded-md
      shadow-sm"
              aria-label="Default select example"
            >
              <option selected>Select Answer</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div className="mt-6">
            <button
              type="button"
              className="w-full inline-flex 
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
              onClick={() => setCurrentStep(3)}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const Step3: FC<any> = ({ setCurrentStep, purchaseAmount }) => {
  const params = useParams()
  const { user } = useUserValue()

  const handleSubmit = () => {
    postNewInvestment({
      slug: params.slug,
      ownerId: Number(user.id),
      amounth: Number(purchaseAmount),
    })
      .then(res => setCurrentStep(4))
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  return (
    <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
      <div className="w-full sm:max-w-3xl p-5 mx-auto">
        <div className="mt-6">
          <label className="block font-bold my-2">
            Total value to purchase = €{purchaseAmount}
          </label>
          <label className="block font-bold my-2">
            Purchased Item Code = {params.slug}
          </label>
          <button
            type="button"
            className="inline-flex my-2
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

const Step4: FC<any> = ({ setCurrentStep }) => {
  return (
    <div className="bg-white font-semibold text-center rounded-lg border shadow-lg p-10 max-w-2xl">
      <FontAwesomeIcon className="text-green-600" icon={faCheck} size="10x" />
      <h1 className="text-xl text-gray-700">Request sent successfully</h1>
    </div>
  )
}

// TODO stepler bireysel sayfalarına
export default Offer
