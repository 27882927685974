const Invite = () => {
  return (
    <div className="flex justify-evenly h-100 py-10">
      <div className="bg-white font-semibold text-center rounded-lg border shadow-lg p-10 max-w-2xl">
        <h1 className="text-xl text-gray-700">Invite Your Friends</h1>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Friends Email
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder=""
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Your Message
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <button
            className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
            type="button"
          >
            Send Mail
          </button>
        </div>
      </div>
    </div>
  )
}

export default Invite
