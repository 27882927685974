import { FC, ReactElement } from 'react'

interface PageHeroProps {
  statusCode?: string
  narrow?: boolean
  title: string
  children: string | ReactElement | HTMLElement
  bgClassName?: string
}

// TODO: single call
const PageHero: FC<PageHeroProps> = ({
  statusCode = '',
  narrow = false,
  title,
  children,
  bgClassName = 'bg-not-found',
}): ReactElement => (
  <div
    className={
      narrow
        ? `container mx-auto animate-fade-in items-center grid sm:grid-cols-1 rounded-lg md:grid-cols-3 ${bgClassName} bg-center bg-cover p-6`
        : `container mx-auto animate-fade-in items-center grid sm:grid-cols-1 rounded-lg md:grid-cols-3 ${bgClassName} bg-center bg-cover p-28`
    }
  >
    <div className="items-center text-left">
      <h1
        className={
          narrow
            ? 'title-font sm:text-4xl text-3xl mb-2 text-gray-900'
            : 'title-font sm:text-4xl text-3xl mb-4 text-gray-900'
        }
      >
        {!!statusCode.length && (
          <span className="font-bold">{statusCode}&nbsp;</span>
        )}
        {title}
      </h1>
      <span
        className={
          narrow
            ? 'inline-block h-1 w-24 rounded bg-orange-500 mt-3 mb-2'
            : 'inline-block h-1 w-24 rounded bg-orange-500 mt-6 mb-4'
        }
      />
      {children}
    </div>
  </div>
)

export default PageHero
