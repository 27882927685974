import { useOutletContext } from 'react-router-dom'
import KYCLevelCard from './KYCLevelCard'
import KYCLevelCardLine from './KYCLevelCardLine'

const Settings = () => {
  const data: any = useOutletContext()
  return (
    <div className="grid grid-cols-3 gap-3 justify-evenly h-100 py-10 px-4">
      <div className="col-span-3">
        <h2 className="text-2xl text-orange-600 mb-4">KYC Steps</h2>
        <div className="col-span-3 grid grid-cols-3 gap-2">
          <KYCLevelCard
            title="Level 0"
            doneText="Your email & mobile verified!"
            isDone
          >
            <KYCLevelCardLine name="Crypto Deposit">
              Needs L1 KYC
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Crypto Withdrawal">
              Needs L1 KYC
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Deposit">
              Needs L1 KYC
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Withdrawal">
              Needs L1 KYC
            </KYCLevelCardLine>
          </KYCLevelCard>
          <KYCLevelCard
            title="Level 1"
            doneText="Your basic information verified!"
            isDone={data.kyc >= 1}
          >
            <KYCLevelCardLine name="Crypto Deposit">No Limit</KYCLevelCardLine>
            <KYCLevelCardLine name="Crypto Withdrawal">
              Needs L2 KYC
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Deposit">
              <span>
                1,000,000 TRY/D
                <br />
                1,000,000 TRY/M
              </span>
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Withdrawal">
              Needs L2 KYC
            </KYCLevelCardLine>
          </KYCLevelCard>
          <KYCLevelCard
            title="Level 2"
            doneText="Your identity documents verified!"
            isDone={data.kyc >= 2}
          >
            <KYCLevelCardLine name="Crypto Deposit">No Limit</KYCLevelCardLine>
            <KYCLevelCardLine name="Crypto Withdrawal">
              <span>
                1,000,000 TRY/D
                <br />
                1,000,000 TRY/M
              </span>
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Deposit">
              <span>
                1,000,000 TRY/D
                <br />
                1,000,000 TRY/M
              </span>
            </KYCLevelCardLine>
            <KYCLevelCardLine name="Fiat Withdrawal">No Limit</KYCLevelCardLine>
          </KYCLevelCard>
        </div>
      </div>
      <h2 className="col-span-3 text-2xl text-orange-600 my-4">
        User Information
      </h2>
      <div className="bg-white font-semibold text-center rounded-lg border shadow-lg p-10 max-w-2xl">
        <h1 className="text-xl text-gray-700">Change Information</h1>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Full Name
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder=""
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Email
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Adress
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Phone Number
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <button
            className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
            type="button"
          >
            Save Changes
          </button>
        </div>
      </div>
      <div className="bg-white font-semibold text-center rounded-lg border shadow-lg p-10 max-w-2xl">
        <h1 className="text-xl text-gray-700">Change Password</h1>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Old Password
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            New Password
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <span className="uppercase text-sm text-gray-600 font-bold">
            New Password Again
          </span>
          <input
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-8">
          <button
            className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
            type="button"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  )
}

export default Settings
