import { FC } from 'react'
import './ContactMap.scss'

const Map: FC = () => {
  return (
    <div className="map-container">
      <div className="flex-1 bg-white">
        <iframe
          title="google map location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d12771.789796015135!2d30.769475499999995!3d36.84373459999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2str!4v1639055786702!5m2!1sen!2str"
          width="100%"
          height="500"
          allowFullScreen
          loading="lazy"
        />
      </div>
    </div>
  )
}

export default Map
