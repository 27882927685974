import { FC, ReactElement, useRef } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CSSTransition } from 'react-transition-group'
import './MediaCard.scss'

interface MediaCardProps {
  imageSrc: string
  imageAlt: string
  children: string
}

const MediaCard: FC<MediaCardProps> = ({
  imageSrc,
  imageAlt,
  children,
}): ReactElement => {
  const nodeRef = useRef(null)

  return (
    <div className="media-card">
      <div className="box">
        <p>{children}</p>
      </div>
      <CSSTransition
        appear
        in
        classNames="image-effect"
        nodeRef={nodeRef}
        timeout={1000}
      >
        <div className="image-box">
          <img src={imageSrc} alt={imageAlt} />
        </div>
      </CSSTransition>
    </div>
  )
}

export default MediaCard
