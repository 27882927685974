import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

const Footer: FC = (): ReactElement => (
  <footer>
    <div className="grid grid-cols-1 gap-y-2">
      <div className="upper">
        <div className="logo">
          <img src="/media/logo-white.png" alt="Logo" />
        </div>
        <div className="address">
          <p>Ercivan İş Merkezi No:2 Muratpaşa/ANTALYA</p>
          <div className="details">
            info@bkinvestment.com.tr | (+90)02422222222
          </div>
        </div>
        <div className="social">
          <Link to="/#" target="_blank">
            <FontAwesomeIcon icon={faFacebook} size="sm" />
          </Link>
          <Link to="/#" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="sm" />
          </Link>
          <Link to="/#" target="_blank">
            <FontAwesomeIcon icon={faTwitter} size="sm" />
          </Link>
          <Link to="/#" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} size="sm" />
          </Link>
        </div>
      </div>
      <div className="hr" />
      <div className="lower">
        <p>© {new Date().getFullYear()} BK Investment</p>
      </div>
    </div>
  </footer>
)

export default Footer
