import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageHero from '../shared/PageHero'

/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO: floating labels
const Verification = () => {
  const [verificationFinished, setVerificationFinished] = useState(false)
  const [codeFinished, setCodeFinished] = useState(true)
  const ibEmailCode = useRef<any>(null)
  const ibSMSCode = useRef<any>(null)
  const navigate = useNavigate()

  const handleSMS = () => {
    setCodeFinished(false)
  }
  const handleVerification = () => {
    if (errorControl()) {
      setVerificationFinished(true)

      navigate('/login')
    }
  }
  const errorControl = () => {
    if (ibEmailCode.current.value && ibSMSCode.current.value) {
      return true
    }
    return false
  }
  return (
    <section className="text-gray-600 body-font space-y-10 mb-20">
      <PageHero title="Email SMS" narrow bgClassName="bg-team">
        <p className="leading-relaxed">
          Please check your emails and copy the code we sent you and paste here
          to activate your account.
        </p>
      </PageHero>

      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <div className="w-full sm:max-w-md p-5 mx-auto">
          <form>
            {/* Email */}
            <div className="mb-4">
              <label className="block font-bold">Email code</label>
              <input
                type="email"
                placeholder="_ _ _ _ _ _"
                ref={ibEmailCode}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Mobile */}
            <div className="mb-4">
              <label className="block font-bold">SMS Code</label>
              <div className="flex">
                <input
                  type="tel"
                  disabled={codeFinished}
                  placeholder="_ _ _ _ _ _"
                  ref={ibSMSCode}
                  className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-l-md shadow-sm disabled:bg-gray-100 block w-full"
                />
                <button
                  title="Send code to: +90 5XX XXX XX XX"
                  type="button"
                  disabled={!codeFinished}
                  className="w-full inline-flex
            items-center justify-center py-2 px-3
            bg-orange-500 border border-transparent rounded-r-md
            font-semibold capitalize text-white
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none
            focus:border-orange-700
            focus:ring
            focus:ring-orange-200
            disabled:opacity-25 transition"
                  onClick={handleSMS}
                >
                  Send SMS
                </button>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="button"
                disabled={codeFinished && !verificationFinished}
                className="w-full inline-flex 
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
                onClick={handleVerification}
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Verification
