import { FC } from 'react'
import ContactForm from './ContactForm'
import ContactMap from './ContactMap'
import ContactTable from './ContactTable'
import './Contact.scss'

const Contact: FC = () => {
  return (
    <>
      <ContactMap />
      <div className="container mx-auto">
        <ContactForm />
        <ContactTable />
      </div>
    </>
  )
}

export default Contact
