import { FC, ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './GalleryBand.scss'

interface GalleryBandProps {
  images: Array<string>
}

// TODO: Extract styles to scss file

const GalleryBand: FC<GalleryBandProps> = ({ images }): ReactElement => {
  const [modalDisplay, setModalDisplay] = useState<null | string>(null)

  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape') setModalDisplay(null)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey, false)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey, false)
    }
  }, [])

  return (
    <div className="flex flex-nowrap overflow-x-auto">
      {images.map((el: string) => (
        <button
          type="button"
          className="flex-none"
          tabIndex={-1}
          onClick={() => setModalDisplay(el)}
          key={el}
        >
          <img src={el} alt="" className="object-fill grow max-h-96" />
        </button>
      ))}

      {/* Modal */}
      {modalDisplay && (
        <div className="fixed inset-0 w-screen h-screen bg-gray-900 backdrop-opacity-50 overflow-hidden z-100 animate-fade-in">
          <div className="flex object-cover justify-center content-center w-full h-full p-10">
            <img src={modalDisplay} alt="" className="object-cover" />
            <button
              type="button"
              onClick={() => setModalDisplay(null)}
              className="fixed top-2 right-2 text-gray-300 hover:text-gray-500"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default GalleryBand
