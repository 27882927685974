/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileUpload,
  faFingerprint,
  faSpaceShuttle,
} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { postKyc } from '../../../data/connections'

const KVC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const data: any = useOutletContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [level, setLevel] = useState(Number(data.kyc) + 1) // TODO: set level by the operation respectively

  const handleSubmit = () => {
    postKyc({ id: Number(data.id) })
      .then(res => console.log(res))
      .catch(err => console.log(err))
    setIsSubmitted(true)
  }

  return (
    <div className="flex justify-evenly py-10">
      {isSubmitted && (
        <div className="grid grid-cols-1 gap-4 justify-items-center content-start pt-20 animate-fade-in-up-big">
          <FontAwesomeIcon
            className="text-orange-600 animate-bounce infinite"
            icon={faSpaceShuttle}
            transform={{ rotate: -90 }}
            size="5x"
          />
          <h2 className="text-2xl">KYC Form Submitted!</h2>

          <p className="text-gray-600">
            Your KYC process is started, you will soon receive an email from us.
          </p>
        </div>
      )}
      {!isSubmitted && level === 1 ? (
        <div className="bg-white font-semibold rounded-lg border shadow-lg p-10 max-w-2xl">
          <h1 className="text-xl text-gray-700">KYC Level 1 Process</h1>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">
              Name
            </span>
            <input
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              placeholder=""
            />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">
              Surname
            </span>
            <input
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              placeholder=""
            />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">
              ID Number
            </span>
            <input
              placeholder="_ _ _ _ _ _ _ _ _ _ _"
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
            />
          </div>
          <div className="mt-8">
            <button
              onClick={handleSubmit}
              className="block btn-lg bg-orange-600 btn-primary align-self-end"
              type="button"
            >
              Verify & Complete
            </button>
          </div>
        </div>
      ) : (
        !isSubmitted && (
          <div className="bg-white w-2/5 font-semibold rounded-lg border shadow-lg p-10 max-w-2xl">
            <h1 className="text-xl text-gray-700">KYC Level 2 Process</h1>
            <div className="mt-8 flex justify-center">
              <FontAwesomeIcon
                className="text-orange-600"
                icon={faFingerprint}
                size="5x"
              />
            </div>
            <div className="mt-8">
              <button
                className="w-full btn-lg rounded-lg bg-gray-100 text-orange-100 text-2xl"
                type="button"
              >
                <FontAwesomeIcon
                  className="text-orange-100 ml-auto"
                  icon={faFileUpload}
                  size="1x"
                />{' '}
                Front side of your ID
              </button>
            </div>
            <div className="mt-8">
              <button
                className="w-full btn-lg rounded-lg bg-gray-100 text-orange-100 text-2xl"
                type="button"
              >
                <FontAwesomeIcon
                  className="text-orange-100"
                  icon={faFileUpload}
                  size="1x"
                />{' '}
                Back side of your ID
              </button>
            </div>
            <div className="mt-8">
              <span className="text-sm text-gray-600 font-bold">
                Your selfie with todays date on a paper with your handwritings.
              </span>
              <button
                className="w-full btn-lg rounded-lg bg-gray-100 text-orange-100 text-2xl"
                type="button"
              >
                <FontAwesomeIcon
                  className="text-orange-100"
                  icon={faFileUpload}
                  size="1x"
                />{' '}
                Your Selfie with date
              </button>
            </div>
            <div className="mt-8">
              <button
                onClick={handleSubmit}
                className="w-full btn-lg bg-orange-600 btn-primary align-self-end"
                type="button"
              >
                Verify & Complete
              </button>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default KVC
