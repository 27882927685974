import { FC, ReactElement } from 'react'
import {
  faCalendarCheck,
  faCubes,
  faSpaceShuttle,
  faDesktop,
  faPlay,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { faKeyboard, faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CarouselCard from '../../components/CarouselCard'
import Button from '../../components/Button'
import IconCard from '../../components/IconCard'
import MediaCard from '../../components/MediaCard'
import HomeCarousel from '../../components/HomeCarousel'
import { investments } from '../../data/marketplace/investments'

// TODO: move the main tag up to the layout(other pages has no main tag)
// TODO: implement home's own scss file and refactor classes
// TODO: split sections on their files

const Home: FC = (): ReactElement => {
  const iconBoxes = [
    {
      title: 'Revolutionary marketplace',
      icon: faCubes,
      description:
        'We are revamping the way we do business by merging finance and investment with a traditional marketplace.',
    },
    {
      title: 'Invest with us',
      icon: faCommentDots,
      description:
        'With our services, you can now invest in anything you want, anytime you want—from stocks to cryptocurrencies. It is never been easier to invest with bkinvestment!',
    },
    {
      title: 'A new way to do business',
      icon: faCalendarCheck,
      description:
        'We are here to empower people like you by giving you the resources and ability to be your own boss through investing in your future.',
    },
    {
      title: 'Flip the system',
      icon: faDesktop,
      description:
        'With bkinvestment, you can browse through different investment opportunities and decide what is best for you. We combine the best of both worlds to provide you with a unique experience that leaves nothing to be desired.',
    },
    {
      title: 'Value for money',
      icon: faSpaceShuttle,
      description:
        'Trading on our platform helps you avoid expensive fees associated with investing in funds or paying commissions for trading stocks. We offer free trading* on all assets, so there is no need to worry about opening your wallet before getting started!',
    },
    {
      title: 'Secure and easy to use',
      icon: faKeyboard,
      description:
        'We take security and ease of use very seriously. Our platform is built with security in mind and we make it as easy as possible for first-time investors or those new to trading to get started. You can even chat with an expert directly on the site!',
    },
  ]

  return (
    <>
      <div className="bg-first">
        <section className="section-hero">
          <h1>
            BK Investment develops creative solutions for investors of all
            niches
          </h1>
          <div className="buttons">
            <Button lg primary route="/">
              Explore services
            </Button>
            <Button lg route="/">
              <FontAwesomeIcon className="text-orange-500" icon={faPlay} />
              <span> Watch the video</span>
            </Button>
          </div>
        </section>

        <div className="section-carousel">
          <HomeCarousel hasDots>
            {investments.map((e, i) => (
              <CarouselCard
                key={e.id}
                tabIndex={e.id}
                title={e.title}
                route={`/marketplace/${e.slug}`}
                imgSrc={e.images[0]}
              >
                {e.body}
              </CarouselCard>
            ))}
          </HomeCarousel>
        </div>

        <section className="section-info">
          <h3>The future is here</h3>

          {iconBoxes.map(box => (
            <IconCard
              title={box.title}
              key={`ib${Math.random()}`}
              icon={
                <FontAwesomeIcon
                  size="3x"
                  className="text-orange-500"
                  icon={box.icon}
                />
              }
              description={box.description}
            />
          ))}
        </section>

        <section className="section-media">
          <div className="left">
            <h3>Professional Portfolio for All</h3>
            <p>
              The 3 Most Important Reasons for Real Estate as an Attractive
              Asset Class
            </p>
            <ul>
              <li>
                <FontAwesomeIcon size="lg" icon={faCheck} />
                <span>Risk optimization and expertise</span>
              </li>
              <li>
                <FontAwesomeIcon size="lg" icon={faCheck} />
                <span>Partnership with banks</span>
              </li>
              <li>
                <FontAwesomeIcon size="lg" icon={faCheck} />
                <span>High quality management</span>
              </li>
            </ul>
          </div>
          <div className="slider">
            <MediaCard
              imageSrc="https://www.bkinvestment.com.tr/assets/media/bkmain/main-1.jpg"
              imageAlt="Galler"
            >
              Established networks provide access to first-class investment
              opportunities. Together with independent appraisers, our experts
              examine every investment opportunity. The focus here is not on the
              maximum return, but on risk optimization.
            </MediaCard>
          </div>
        </section>
      </div>

      <div className="bg-second">
        <section className="section-headline section-headline-reverse">
          <h3>Professional Portfolio for All</h3>
          <div className="left">
            <img src="/media/box-2.png" alt="" />
          </div>
          <div className="right">
            <h4>Time-consuming</h4>
            <p>
              We facilitate the extremely time-consuming management of a
              tangible asset through professional management in order to
              generate the best possible return.
            </p>
            <div>
              <Button primary route="/">
                View details
              </Button>
              <Button className="ml-2" route="/">
                Information
              </Button>
            </div>
          </div>
        </section>

        <section className="section-headline">
          <div className="left">
            <img src="/media/box-1.png" alt="" />
          </div>
          <div className="right">
            <h4>Information asymmetries</h4>
            <p>
              We offer a professional analysis of the asset to be financed to
              prevent uneconomical investment decisions.
            </p>
            <div>
              <Button primary route="/">
                View details
              </Button>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-third">
        <section className="section-team">
          <div className="left">
            <h3>Easier way</h3>
            <p>
              The fact is, you can`t make a profit if you don`t invest. But
              investing in a new company, even with the best intentions, isn`t
              always easy. That`s why we created bkinvestment – to make it
              easier for entrepreneurs to find funding and provide an investment
              opportunity for those who want to invest.
            </p>
          </div>
          <div className="card">
            <div className="text">
              <h4>Premium support</h4>
              <p>
                We are at your service to deal with all your questions and
                problems.
              </p>
              <span>10.000+</span> Costumers
            </div>
            <div className="list">
              <ul>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>Experienced team</span>
                </li>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>7/24 support</span>
                </li>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>Thousands costumers</span>
                </li>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>Access to alternative investments</span>
                </li>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>Newest technologies</span>
                </li>
                <li>
                  <FontAwesomeIcon size="lg" icon={faCheck} />
                  <span>Revolutionary flow work method</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div className="section-footer-carousel">
          <HomeCarousel slidesToShow={4}>
            {investments.map((e, i) => (
              <CarouselCard
                key={e.id}
                tabIndex={e.id}
                route={`/marketplace/${e.slug}`}
                imgSrc={e.images[0]}
                imgAlt={e.title}
              />
            ))}
          </HomeCarousel>
        </div>
      </div>
    </>
  )
}

export default Home
