import { FC, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/Button'

interface CarouselCardProps {
  title: string
  isDone: boolean
  children: ReactElement[]
  doneText: string
}

const CarouselCard: FC<CarouselCardProps> = ({
  title = '',
  isDone = false,
  children,
  doneText,
}): ReactElement => {
  return (
    <div className="grid grid-cols-1 content-between bg-white font-semibold rounded-lg border shadow-lg p-10 max-w-2xl">
      <div>
        <div
          className={
            isDone
              ? 'rounded-full bg-orange-500 w-12 h-12 flex mx-auto mb-2 justify-center items-center'
              : 'rounded-full bg-gray-100 w-12 h-12 flex mx-auto mb-2 justify-center items-center'
          }
        >
          <FontAwesomeIcon
            className="text-white"
            icon={isDone ? faCheck : faTimes}
            size="2x"
          />
        </div>
        <h1
          className={
            isDone
              ? 'text-xl text-center text-orange-600'
              : 'text-xl text-center text-gray-600'
          }
        >
          {title}
        </h1>
      </div>
      <div className="mt-10 text-sm font-medium text-gray-500">
        <ul className="list-none grid grid-cols-1 gap-4 mb-8">{children}</ul>
      </div>

      {isDone ? (
        <span className="text-black font-bold text-lg align-self-end">
          {doneText}
        </span>
      ) : (
        <Button
          className="block align-self-end"
          primary
          route="/account/kyc"
          lg
        >
          Complete
        </Button>
      )}
    </div>
  )
}

export default CarouselCard
