import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import PageHero from './PageHero'

const NotFound: FC = (): ReactElement => (
  <div className="h-screen">
    <PageHero statusCode="404" title="Not found">
      <p className="leading-relaxed">
        Sorry the page you are looking for is not here. Please, navigate through
        navbar or{' '}
        <Link className="text-orange-900" to="/contact">
          contact us
        </Link>{' '}
        if you could not find the related page.
      </p>
    </PageHero>
  </div>
)

export default NotFound
