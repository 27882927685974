import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import './Button.scss'

interface ButtonProps {
  children: ReactElement[] | string | ReactElement
  className?: string
  route: string
  lg?: boolean
  primary?: boolean
  disabled?: boolean
}

const Button: FC<ButtonProps> = ({
  children,
  className = '',
  route,
  lg = false,
  primary = false,
  disabled = false,
}): ReactElement => {
  return (
    <Link
      className={`btn${lg ? ' btn-lg' : ''}${
        primary ? ' btn-primary' : ''
      } ${className} ${disabled ? 'pointer-events-none' : ''}`}
      to={route}
    >
      {children}
    </Link>
  )
}

export default Button
