/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import AboutText from './sections/AboutText'
import AboutText2 from './sections/AboutText2'
import AboutTabBox from './sections/AboutTabBox'
import PageHero from '../shared/PageHero'

const About: FC = () => {
  return (
    <section className="text-gray-600 body-font space-y-20 mb-20">
      <PageHero title="About Us" bgClassName="bg-about">
        <p className="mb-8 leading-relaxed">
          We identified a clear correlation between their superior performance
          relative to the average individual investor and their various
          portfolio structures.
        </p>
      </PageHero>

      <AboutText />
      <AboutText2 />
    </section>
  )
}

export default About
