import { Link } from 'react-router-dom'
import PageHero from '../shared/PageHero'

/* eslint-disable jsx-a11y/label-has-associated-control */
const PasswordReset = () => {
  // TODO: floating labels
  return (
    <section className="text-gray-600 body-font space-y-10 mb-20">
      <PageHero title="Reset Password" narrow bgClassName="bg-team">
        <p className="leading-relaxed">Reset your password.</p>
      </PageHero>

      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <div className="w-full sm:max-w-md p-5 mx-auto">
          <form>
            {/* Password */}
            <div className="mb-4">
              <label className="block font-bold">Password</label>
              <input
                type="password"
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Password 2 */}
            <div className="mb-4">
              <label className="block font-bold">Password Again</label>
              <input
                type="password"
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mt-6">
              <button
                type="button"
                className="w-full inline-flex 
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
              >
                Reset
              </button>
            </div>
            <div className="mt-6 text-center">
              <Link to="/login" className="hover:underline">
                Back to log in.
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default PasswordReset
