import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './home/Home'
import Contact from './contact/Contact'
import About from './about/About'
import Team from './team/Team'
import Marketplace from './marketplace/Marketplace'
import Layout from './shared/Layout'
import Product from './marketplace/product/Product'
import Login from './auth/Login'
import Register from './auth/Register'
import { UserProvider } from '../contexts/UserContext'
import Account from './account/Account'
import RequireAuth from './shared/RequireAuth'
import RedirectLogged from './shared/RedirectLogged'
import Wallet from './account/wallet/Wallet'
import Investments from './account/investments/Investments'
import Settings from './account/settings/Settings'
import Invite from './account/invite/Invite'
import PasswordRequest from './auth/PasswordRequest'
import PasswordReset from './auth/PasswordReset'
import NotFound from './shared/NotFound'
import Verification from './auth/Verification'
import Offer from './marketplace/offer/Offer'
import KYC from './account/kyc/KYC'
import Deposit from './account/wallet/Deposit'
import Withdraw from './account/wallet/Withdraw'

const App: FC = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="team" element={<Team />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="marketplace" element={<Marketplace />} />
          <Route path="marketplace/:slug" element={<Product />} />
          <Route path="marketplace/:slug/offer" element={<Offer />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="login"
            element={
              <RedirectLogged>
                <Login />
              </RedirectLogged>
            }
          />
          <Route
            path="/password-request"
            element={
              <RedirectLogged>
                <PasswordRequest />
              </RedirectLogged>
            }
          />
          <Route
            path="/password-reset"
            element={
              <RedirectLogged>
                <PasswordReset />
              </RedirectLogged>
            }
          />
          <Route
            path="register"
            element={
              <RedirectLogged>
                <Register />
              </RedirectLogged>
            }
          />
          <Route
            path="verification"
            element={
              <RedirectLogged>
                <Verification />
              </RedirectLogged>
            }
          />
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          >
            <Route index element={<Wallet />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="investments" element={<Investments />} />
            <Route path="settings" element={<Settings />} />
            <Route path="invite" element={<Invite />} />
            <Route path="kyc" element={<KYC />} />
          </Route>
        </Route>
      </Routes>
    </UserProvider>
  )
}

export default App
