/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUserValue } from '../../contexts/UserContext'
import './Login.scss'
import PageHero from '../shared/PageHero'
import { postLogin } from '../../data/connections'

const Login = () => {
  const [errors, setErrors] = useState(null)
  const navigate = useNavigate()
  const { loginUser } = useUserValue()
  const ibEmail = useRef<any>(null)
  const ibPass = useRef<any>(null)

  const handleLogin = () => {
    if (ibEmail.current.value && ibPass.current.value) {
      postLogin({ email: ibEmail.current.value })
        .then(res => {
          loginUser(res) // TODO check type error, fn accepts any
          navigate('/account')
        })
        .catch(err => setErrors(err))
    }
  }

  return (
    <section className="text-gray-600 body-font space-y-10 mb-20">
      <PageHero title="Sign In" narrow bgClassName="bg-team">
        <p className="leading-relaxed">
          Please login to manage your inverstments.
        </p>
      </PageHero>

      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <div className="w-full sm:max-w-md p-5 mx-auto">
          {errors ? (
            <div className="p-2">
              <div className="inline-flex items-center bg-white leading-none text-pink-600 rounded-full p-2 shadow text-teal text-bold text-lg">
                <span className="inline-flex bg-pink-600 text-white rounded-full h-6 px-3 justify-center items-center">
                  Error
                </span>
                <span className="inline-flex px-2">Incorrect information.</span>
              </div>
            </div>
          ) : null}
          <form>
            <div className="mb-4">
              <label className="block font-bold mb-1" htmlFor="email">
                Email-Address
              </label>
              <input
                type="text"
                ref={ibEmail}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                ref={ibPass}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mt-6 flex items-center justify-between">
              <Link to="/password-request" className="text-sm">
                Forgot your password?
              </Link>
            </div>
            <div className="mt-6">
              <button
                type="button"
                className="w-full inline-flex
              items-center justify-center px-4 py-2
              bg-orange-500 border border-transparent rounded-md
              font-semibold capitalize text-white
              hover:bg-orange-700
              active:bg-orange-700
              focus:outline-none
              focus:border-orange-700
              focus:ring
              focus:ring-orange-200
              disabled:opacity-25 transition"
                onClick={handleLogin}
              >
                Sign In
              </button>
            </div>
            <div className="mt-6 text-center">
              <Link to="/register" className="hover:underline">
                Sign up for an account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Login
