import { useNavigate } from 'react-router-dom'
import { useUserValue } from '../../contexts/UserContext'

const SidebarMenu = () => {
  const { user, logoutUser } = useUserValue()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col justify-start h-full">
      <div className="header flex flex-col items-center mb-10">
        <div className="w-20 h-20 rounded-lg bg-cover bg-center bg-no-repeat bottom-0 shadow flex items-center justify-center">
          <img
            src="https://cdn.tuk.dev/assets/photo-1566753323558-f4e0952af115.jfif"
            alt=""
            className="h-full w-full object-cover rounded-full shadow top-0 left-0 bottom-0 right-0"
          />
        </div>
        <h3 className="text-xl">My Account</h3>
        <h3 className="text-sm">Welcome, {user.username}</h3>
      </div>
      <div className="middle-items">
        <div className="flex justify-center my-2">
          <button
            className="btn w-2/3 btn-danger"
            type="button"
            onClick={() => navigate('/account')}
          >
            Dashboard
          </button>
        </div>
        <div className="flex justify-center my-2">
          <button
            className="btn w-2/3 btn-danger"
            type="button"
            onClick={() => navigate('/account/investments')}
          >
            My Investments
          </button>
        </div>
        <div className="flex justify-center my-2">
          <button
            className="btn w-2/3 btn-danger"
            type="button"
            onClick={() => navigate('/account/settings')}
          >
            Settings
          </button>
        </div>
        <div className="flex justify-center my-2">
          <button
            className="btn w-2/3 btn-danger"
            type="button"
            onClick={() => navigate('/account/invite')}
          >
            Invite friends
          </button>
        </div>
      </div>
      <div className="bottom-items">
        <div className="flex justify-center my-2">
          <button
            className="btn w-2/3 bg-orange-500 border-orange-500 text-white"
            type="button"
            onClick={() => logoutUser()}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidebarMenu
