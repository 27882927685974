import { FC, ReactElement } from 'react'
import Navbar from './Navbar'

const Header: FC = (): ReactElement => (
  <header>
    <div className="container mx-auto h-full">
      <Navbar />
    </div>
  </header>
)

export default Header
