import { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserValue } from '../../../contexts/UserContext'
import { useDataUser } from '../../../data/connections'
import SidebarFeature from './SidebarFeature'

interface SidebarProps {
  features: Array<object>
  state: string
  progress: string
  ppDS: string
  noDS: string
  finVolume: string
  pofAssets: string
}

const Sidebar: FC<SidebarProps> = ({
  features,
  state,
  progress,
  ppDS,
  noDS,
  finVolume,
  pofAssets,
}): ReactElement => {
  return (
    <div className="flex flex-col PriceCard">
      <div className="pb-4 bg-orange-200 bg-opacity-25 text-gray-100 rounded-xl hover:shadow divide-y-4 divide-slate-400/25">
        <div className="flex row justify-between discount bg-orange-500 rounded-t-lg p-4">
          <span className="text-white-500 text-lg">
            Indikative Gesamtperformance*
          </span>
          <span className="whitespace-nowrap text-3xl">{pofAssets} %</span>
        </div>
        <div className="grid grid-cols-2 px-4">
          <span className="text-left font-bold text-blue-500">
            Kaufpreis Diamant?
          </span>
          <span className="text-right  text-blue-500">{finVolume}</span>

          <span className="text-left font-bold text-blue-500">
            Laufzeitbeginn?
          </span>
          <span className="text-right text-blue-500">Feb. 2022</span>

          <span className="text-left font-bold text-blue-500">
            Progn. Laufzeitende?
          </span>
          <span className="text-right text-blue-500">Feb. 2025</span>
        </div>

        {/* Details */}
        <div className="flex flex-col">
          {features.map((el: any) => (
            <SidebarFeature name={el.name} value={el.value} key={el.name} />
          ))}
        </div>
        <div className="p-4 text-center">
          <InvestButton />
        </div>
      </div>
    </div>
  )
}

export default Sidebar

const InvestButton = () => {
  const { user } = useUserValue()
  const { data, isLoading, isError } = useDataUser(user.id)
  const navigate = useNavigate()

  if (isLoading) return <div>Loading</div>
  if (isError) return <div>Hata</div>

  return (
    <button
      className="bg-blue-500 text-2xl py-1 px-10 text-white rounded-md hover:bg-blue-900 "
      type="button"
      disabled={data.kyc === 0}
      onClick={() => navigate('offer')}
    >
      Invest Now
    </button>
  )
}
