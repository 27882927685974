// ! Temporary fake json

interface Investment {
  id: number
  slug: string
  category: number
  images: Array<string>
  title: string
  features: Array<object>
  body: string
  state: string
  progress: string
  ppDS: string
  noDS: string
  finVolume: string
  pofAssets: string
}

export const categoryMap: {
  [key: string]: string
} = {
  '1': 'Shopping Malls',
  '2': 'Hotels',
  '3': 'Luxury Yachts',
}

export const investments: Array<Investment> = [
  {
    id: 1,
    slug: 'novada-elbistan',
    category: 1,
    images: [
      '/media/marketplace/novada-elbistan-1.jpg',
      '/media/marketplace/novada-elbistan-2.jpg',
      '/media/marketplace/novada-elbistan-3.jpg',
      '/media/marketplace/novada-elbistan-4.jpg',
    ],
    title: 'Novada Elbistan',
    features: [
      { name: 'Category', value: 'Shopping Mall' },
      { name: 'Location', value: 'Elbistan,Kahramanmaraş, Turkey' },
      {
        name: 'Project Type',
        value:
          'Preliminary Design – Design Development – Construction Documents',
      },
      { name: 'Year', value: '2016' },
      { name: 'Construction Area', value: '39.000 m²' },
    ],
    body: `
Novada Elbistan is located at Kahramanmaras in mediterenean region. The shopping center is in a place which has a huge pedestrian traffic.

It consists of one basement floor and 4 retail floors.The mall will provide contribution to city’s commercial and social life with modern architecture,combining selective brands, entertainment center, cinemas and market together. 
    `,
    state: 'Funding Phase',
    progress: '50',
    ppDS: '1.00 €',
    noDS: '2,000,000',
    finVolume: '2,000,000 €',
    pofAssets: '5.00',
  },
  {
    id: 2,
    slug: 'mandalina-luxury-suites',
    category: 2,
    images: [
      '/media/marketplace/mandalina-luxury-suites-1.webp',
      '/media/marketplace/mandalina-luxury-suites-2.webp',
      '/media/marketplace/mandalina-luxury-suites-3.webp',
      '/media/marketplace/mandalina-luxury-suites-4.webp',
    ],
    title: 'Mandalina Luxury Suites',
    features: [
      {
        name: 'Main Amenities',
        value: '12 rooms',
      },
      {
        name: ' ',
        value: 'Daily room cleaning',
      },
      {
        name: ' ',
        value: 'On a private beach',
      },
      {
        name: ' ',
        value: 'Restaurants and 2 bars/lounges',
      },
      {
        name: ' ',
        value: 'outdoor pool',
      },
      {
        name: ' ',
        value: 'Free beach shuttle',
      },
    ],
    body: `
Everyone needs a place to lay their weary head. For travelers visiting Kas, Mandalina Luxury Boutique Otel is an excellent choice for rest and rejuvenation. Well-known for its luxury environment and proximity to great restaurants, Mandalina Luxury Boutique Otel makes it easy to enjoy the best of Kas.

Rooms at Mandalina Luxury Boutique Otel provide a minibar, air conditioning, and extra long beds, and guests can stay connected with free wifi.

You can also enjoy an on-site restaurant while staying at Mandalina Luxury Boutique Otel.

Given the close proximity to Hellenistic Theatre (2.9 mi), guests of Mandalina Luxury Boutique Otel can easily experience one of Kas' most popular landmarks.

During your visit, be sure to check out a popular Kas cafes such as Mumi Cafe & Beach, which is a short distance from Mandalina Luxury Boutique Otel.

Mandalina Luxury Boutique Otel is sure to make your visit to Kas one worth remembering.
    `,
    state: 'Funding Phase',
    progress: '30',
    ppDS: '1.00 €',
    noDS: '1,500,000',
    finVolume: '1,500,000 €',
    pofAssets: '4.20',
  },
  {
    id: 3,
    slug: 'kas-lemon-hotel',
    category: 2,
    images: [
      '/media/marketplace/kas-lemon-hotel-1.jpg',
      '/media/marketplace/kas-lemon-hotel-2.jpg',
      '/media/marketplace/kas-lemon-hotel-3.jpg',
      '/media/marketplace/kas-lemon-hotel-4.jpg',
    ],
    title: 'Kaş Lemon Hotel',
    features: [
      {
        name: 'Main Amenities',
        value: '24 rooms',
      },
      {
        name: ' ',
        value: 'Daily room cleaning',
      },
      {
        name: ' ',
        value: 'Restaurants and bar/lounge',
      },
      {
        name: ' ',
        value: 'outdoor pool',
      },
      {
        name: ' ',
        value: 'Terrace',
      },
      {
        name: ' ',
        value: 'Airport Transfer',
      },
    ],
    body: `
See why so many travelers make Kas Lemon Hotel their small hotel of choice when visiting Kas. Providing an ideal mix of value, comfort and convenience, it offers an array of amenities designed for travelers like you.

Kas Lemon Hotel is a small hotel offering air conditioning and a minibar in the rooms, and it is easy to stay connected during your stay as free wifi is offered to guests.

Plus, guests can enjoy a pool and free breakfast, which have made this a popular choice among travelers visiting Kas.

For those interested in checking out popular landmarks while visiting Kas, Kas Lemon Hotel is located a short distance from Flora Boat (2.3 mi) and Kas At Ciftligi (2.4 mi).

When you’re feeling hungry, be sure to check out Uzum Kizi Meyhane, Ruhi Bey Meyhanesi, and Sardelaki Greek Tavern, which are some seafood restaurants that are popular with locals and out-of-towners alike.

Best of all, Kas Lemon Hotel makes it easy to experience many great Kas attractions like Hellenistic Theatre and Kral Mezari, which are some popular ancient ruins.

We’re sure you’ll enjoy your stay at Kas Lemon Hotel as you experience all of the things Kas has to offer.
    `,
    state: 'Funding Phase',
    progress: '40',
    ppDS: '1.00 €',
    noDS: '1,800,000',
    finVolume: '1,800,000 €',
    pofAssets: '3.50',
  },
  {
    id: 4,
    slug: 'ms-admiral',
    category: 3,
    images: [
      '/media/marketplace/ms-admiral-1.jpg',
      '/media/marketplace/ms-admiral-2.jpg',
      '/media/marketplace/ms-admiral-3.jpg',
      '/media/marketplace/ms-admiral-4.jpg',
    ],
    title: 'MS Admiral',
    features: [
      { name: 'Baujahr', value: '2013, 3 Mast Luxus' },
      { name: 'Gulet Saison', value: ' April-Oktober' },
      { name: 'Gesamtlänge', value: '60m' },
      { name: 'Breite', value: '9,40m' },
      { name: 'Tiefgang', value: '4,20 m' },
      { name: 'Segelfläche am Wind', value: '460 qm' },
      { name: 'Masthöhe über Deck', value: '35 m' },
      { name: 'Werft/Ausführung', value: 'Ayna Tershanesi , Fethiye' },
      { name: 'Konstruktion/Styling', value: 'Ihsan Kizar' },
      { name: 'Indienststellung', value: '2013' },
      { name: 'Heimathafen', value: 'Istanbul' },
      { name: 'Management/Eigner', value: 'Admiral Yatcilik' },
      { name: 'Max. Geschwindigkeit unter Segel', value: '6 kn' },
      { name: 'Max. Geschwindigkeit unter Motor', value: '14 kn' },
      { name: 'Dieseltank', value: '12 t' },
      { name: 'Trinkwassertank', value: '28 t' },
      { name: 'Motorleistung', value: '2 Motoren à 550 HP (Cumins)' },
      {
        name: 'Generatoren / Leistung',
        value: '5 Generatoren à 33 KW ( Onan )',
      },
      {
        name: 'Wasseraufbereitungsanlage',
        value:
          'pro h 800 lt. Wasseraufbereitung in Trinkwasser-Qualität (Selmar Superior 700)',
      },
      {
        name: 'Abwasseraufbereitung',
        value: 'vollautomatisches Klärsystem (Blue Sea 7000)',
      },
      { name: 'Stromversorgung', value: 'Elektrik 24/220 Volt tagsüber' },
      {
        name: 'Klimaanlage',
        value:
          'ausgelegt bis 40° C, Hydraulische Segel- und Ankerwinden, Navigationsanlage mit SAT NAV, Grenzwellen-Sende- und Empfangsanlage, Radar,, Beiboote Joker / je 30 PS, 2 Steuerstände, Rettungsinseln für 44 Personen.',
      },
      { name: 'Crew-Kabinen', value: '1 Kapitänskajüte / 3 Crew-Kabinen' },
      { name: 'Crew', value: '7 Personen' },
      {
        name: 'Decksalon',
        value: '140 qm, Plasma-TV,DVD-System, Raymarin SAT-Antenne',
      },
      {
        name: 'Küche',
        value:
          'Gastronomiegerechte Kombüse mit Backofen, Grill, Geschirrspülmaschine, etc., angrenzender Proviantraum.',
      },
      {
        name: 'Kabinen',
        value:
          'Die verschiedenen Kabinenkategorien in hochwertiger Ausführung und maritimen Ambiente bieten viel Flexibilität in der Unterbringung. Neben 12 Doppelkabinen (davon 2 mit Twinbeds) verfügt die MS Admiral auch über 4 Triple-Kabinen (keine Etagenbetten!). Selbstverständlich verfügt jede Kabine über ein eigenes Bad en-suite in einer ebenfalls edlen Ausstattung.Salon & Sonnendeck: Der fast 140 qm große Salon in edler Ausführung teilt sich in 3 großzügige Sitzecken auf. Am Vorderdeck befindet sich die offene Bar und Sitzplätze.',
      },
    ],
    body: `
With a total length of 60 m our 3-master is incomparable and ideal for large groups and families. Aboard the MS ADMIRAL, you will experience a coastal cruise of a special kind. The blue sea, the bright sky and the wonderful natural bays are the perfect setting for a dream week on our deluxe gulet. 

The different cabin categories in high-quality execution and maritime ambience offer a lot of flexibility for accommodation. In addition to 12 double cabins (2 with twin beds), the MS Admiral also has 4 triple cabins (no bunk beds!). Of course, each cabin has its own bathroom en-suite in a likewise noble equipment. 

 The nearly 140 square meter salon in classic maritime style is separated in 3 spacious seating areas. At the front deck is the open bar and seats. On the larger than average sundeck with ample sun loungers you can your soul run free. 
    `,
    state: 'Funding Phase',
    progress: '45',
    ppDS: '1.00 €',
    noDS: '2,800,000',
    finVolume: '2,800,000 €',
    pofAssets: '5.50',
  },
  {
    id: 5,
    slug: 'ms-grand-admiral',
    category: 3,
    images: [
      '/media/marketplace/ms-grand-admiral-1.jpg',
      '/media/marketplace/ms-grand-admiral-2.jpg',
      '/media/marketplace/ms-grand-admiral-3.jpg',
      '/media/marketplace/ms-grand-admiral-4.jpg',
    ],
    title: 'MS Grand Admiral',
    features: [
      { name: 'Baujahr', value: '2006' },
      { name: 'Generalüberholung', value: '2014, 3 Mast Luxus' },
      { name: 'Gulet Saison', value: 'April-Oktober' },
      { name: 'Gesamtlänge', value: '63m' },
      { name: 'Breite', value: '9,10m' },
      { name: 'Tiefgang', value: '4,20 m' },
      { name: 'Segelfläche am Wind', value: '460 qm' },
      { name: 'Masthöhe über Deck', value: '36 m' },
      { name: 'Werft/Ausführung', value: 'Ayna Tershanesi , Fethiye' },
      { name: 'Konstruktion/Styling', value: 'Tahir Nezihi Özdemir' },
      { name: 'Heimathafen', value: 'Istanbul' },
      { name: 'Management/Eigner', value: 'Admiral Yatcilik' },
      { name: 'Max. Geschwindigkeit unter Segel', value: '8 kn' },
      { name: 'Max. Geschwindigkeit unter Motor', value: '16 kn' },
      { name: 'Dieseltank', value: '6 t' },
      { name: 'Trinkwassertank', value: '20 t' },
      { name: 'Motorleistung', value: '2 Motoren à 450 HP (Iveco)' },
      {
        name: 'Generatoren / Leistung',
        value: '2 Generatoren à 4 KW (Iveco) ',
      },
      {
        name: 'Wasseraufbereitungsanlage',
        value:
          'pro h 800 lt. Wasseraufbereitung in Trinkwasser-Qualität (Selmar Superior 700)',
      },
      {
        name: 'Abwasseraufbereitung',
        value: 'vollautomatisches Klärsystem (Blue Sea 7000)',
      },
      { name: 'Stromversorgung', value: 'Elektrik 24/220 Volt tagsüber' },
      {
        name: 'Klimaanlage',
        value:
          'ausgelegt bis 40° C, Hydraulische Segel- und Ankerwinden, Navigationsanlage mit SAT NAV, Grenzwellen-Sende- und Empfangsanlage, Radar., Beiboote Joker / je 30 PS, 2 Steuerstände, Rettungsinseln für 44 Personen.',
      },
      { name: 'Crew-Kabinen', value: '1 Kapitänskajüte / 3 Crew-Kabinen' },
      { name: 'Crew', value: '7 Personen' },
      {
        name: 'Decksalon',
        value: '200 qm, Plasma-TV,DVD-System, Raymarin SAT-Antenne',
      },
      {
        name: 'Küche',
        value:
          'Gastronomiegerechte Kombüse mit Backofen, Grill, Geschirrspülmaschine, etc., angrenzender Proviantraum.',
      },
      {
        name: 'Kabinen',
        value:
          'Die liebevoll eingerichteten 18 Doppelkabinen lassen keine Wünsche offen. Ausreichend Schrankraum bietet Platz zum Verstauen der Garderobe. Die Ausstattung mit viel Holz und hochwertigen Lampen sowie Klimaanlage schaffen ein anspruchsvolles, maritimes Ambiente. Selbstverständlich verfügt jede Kabine über ein eigenes Bad en-suite in einer ebenfalls hochwertigen Ausführung.Salon – Sonnendeck: Der fast 200 qm große Salon in maritimen Stil verfügt über eine großzügige Bar und ausreichend Sitzplätze. Eine osmanische Ecke lädt ein zum Backgammon-Spiel, Lesen oder Wasserpfeife. Die perfekte Kulisse von Meer und Küstenlandschaft genießen Sie auf dem großen Sonnendeck, welches über ausreichend Sitz- oder Liegemöglichkeiten verfügt.',
      },
    ],
    body: `
With a total length of 67 m total length, an exclusive equipment, our 3-master is unique and ideal for bigger groups. Aboard the MS GRAND ADMIRAL, you will feel the atmosphere of maritime romance in its original way but with luxurious amenities for passengers today. 

The cabins are beautiful furnished and lovingly decorated. Ample closet space provides enough capacity for stowing your wardrobe. The interior with lots of wood, quality lamps and air conditioning create a sophisticated, maritime ambience. Of course, each cabin comes with aircondition and private bathroom en-suite 

The nearly 200 square meter salon in maritime style has a spacious bar and plenty of seats. An Ottoman corner invites you to play backgammon, chess or reading and talking. The perfect scenery of the sea and coast can be enjoyed on the large sundeck, which has plenty of sunloungers. 
    `,
    state: 'Funding Phase',
    progress: '60',
    ppDS: '1.00 €',
    noDS: '2,400,000',
    finVolume: '2,400,000 €',
    pofAssets: '4.10',
  },
  {
    id: 6,
    slug: 'ms-queen-atlantis',
    category: 3,
    images: [
      '/media/marketplace/ms-queen-atlantis-1.jpg',
      '/media/marketplace/ms-queen-atlantis-2.jpg',
      '/media/marketplace/ms-queen-atlantis-3.jpg',
      '/media/marketplace/ms-queen-atlantis-4.jpg',
    ],
    title: 'MS Queen Atlantis',
    features: [
      { name: 'Baujahr', value: '2014, 2 Mast' },
      { name: 'Gulet Saison', value: 'April-Oktober' },
      { name: 'Gesamtlänge', value: '48m' },
      { name: 'Breite', value: '8,40m' },
      { name: 'Tiefgang', value: '3,80 m' },
      { name: 'Segelfläche am Wind', value: '624 qm' },
      { name: 'Masthöhe über Deck', value: '36 m' },
      { name: 'Werft/Ausführung', value: 'Ayna Tershanesi , Fethiye' },
      {
        name: 'Konstruktion/Styling',
        value: 'Viking Gemi Insaat Müh., Fatma Kisar',
      },
      { name: 'Indienststellung', value: 'Juni 2014' },
      { name: 'Heimathafen', value: 'Istanbul' },
      { name: 'Management/Eigner', value: 'Admiral Yatcilik' },
      { name: 'Max. Geschwindigkeit unter Segel', value: '5 kn' },
      { name: 'Max. Geschwindigkeit unter Motor', value: '12 kn' },
      { name: 'Dieseltank', value: '8 t' },
      { name: 'Trinkwassertank', value: '30 t' },
      { name: 'Motorleistung', value: '550 HP (Cumins)' },
      {
        name: 'Generatoren / Leistung',
        value: '2 Generatoren à 33 KW (Kubato)',
      },
      {
        name: 'Wasseraufbereitungsanlage',
        value:
          'pro h 800 lt. Wasseraufbereitung in Trinkwasser-Qualität (Selmar Superior 700)',
      },
      {
        name: 'Abwasseraufbereitung',
        value: 'vollautomatisches Klärsystem (Blue Sea 7000)',
      },
      { name: 'Stromversorgung', value: 'Elektrik 24/220 Volt tagsüber' },
      {
        name: 'Klimaanlage',
        value:
          'ausgelegt bis 40° C, Hydraulische Segel- und Ankerwinden, Navigationsanlage mit SAT NAV, Grenzwellen-Sende- und Empfangsanlage, Radar, Beiboot Joker  je 30 PS, 2 Steuerstände, Rettungsinseln für 24 Personen',
      },
      { name: 'Crew-Kabinen', value: '1 Kapitänskajüte / 1 Crew-Kabinen' },
      { name: 'Crew', value: '5 Personen' },
      {
        name: 'Decksalon',
        value: '140 qm, Plasma-TV,DVD-System, Raymarin SAT-Antenne',
      },
      {
        name: 'Küche',
        value:
          'Gastronomiegerechte Kombüse mit Backofen, Grill, Geschirrspülmaschine, etc., angrenzender Proviantraum.',
      },
      {
        name: 'Kabinen',
        value:
          'Queen Atlantis bietet Platz für 16 Gäste in 2 Master-Kabinen und 6 weiteren Doppelkabinen (2 davon mit Twinbeds) Hochglänzendes Naturholz, natürliche Farben und exquisite Materialien sorgen für ein maritimes Ambiente. Alle Kabinen verfügen über ein eigenes Bad ensuite, Klimaanlage und Sat-TV.',
      },
      {
        name: 'Großflächiges Sonnendeck',
        value:
          'Die MS Queen Atlantis wurde designed, um unsere Gäste zwischen Meer und Himmel zu verwöhnen. Das großzügige Sonnendeck mit beheizbarem Whirlpool, der elegante Schiffssalon mit integrierter Bar und Sitzecken sowie der Dining-Bereich auf dem Vorderdeck sind die perfekte Location für eine Traumwoche auf dem Meer.',
      },
      {
        name: 'Ausstattung',
        value:
          'Sie ist komplett aus hochwertigem Teakholz gebaut und verfügt über ein elegantes und hochwertiges Design. Die Innenausstattung ist eine raffinierte Mischung aus Klassik und Moderne auf höchstem Niveau.',
      },
    ],
    body: `
MS Queen Atlantis welcomes you aboard for a very special private yacht charter experience. Hand-built in high quality teak, the ship exudes elegance with her superior finish and design. The interior is a refined blend of classic and modern at the highest level. Queen Atlantis was designed to place guests in intimate contact with sea and sky…. and with each other. 

Queen Atlantis accommodates 16 guests in 2 master cabins, 4 guest cabins with double beds and 2 guest cabins with twin beds. All have en-suite bathroom, air-conditioning and satellite TV. High glossy wood, natural colours and lovely details are arranged to an unique ambience. 

The elegant lounge with integrated bar and sitting areas invited you to indulge yourself in the ease of life on the sea. She is meant to be enjoyed on the well-spaced sundeck, for relaxing in the heatable jacuzzi, dining at horizon in the great outdoors along the coast. 
    `,
    state: 'Funding Phase',
    progress: '35',
    ppDS: '1.00 €',
    noDS: '1,600,000',
    finVolume: '1,600,000 €',
    pofAssets: '3.70',
  },
  {
    id: 7,
    slug: 'ms-royal-admiral',
    category: 3,
    images: [
      '/media/marketplace/ms-royal-admiral-1.jpg',
      '/media/marketplace/ms-royal-admiral-2.jpg',
      '/media/marketplace/ms-royal-admiral-3.jpg',
      '/media/marketplace/ms-royal-admiral-4.jpg',
    ],
    title: 'MS Royal Admiral',
    features: [
      { name: 'Baujahr', value: '2021' },
      { name: 'Gulet Saison', value: 'April-Oktober' },
      { name: 'Gesamtlänge', value: '75m' },
      { name: 'Breite', value: '13,5m' },
      { name: 'Tiefgang', value: '4.65 m' },
      { name: 'Masthöhe über Deck', value: '16 m' },
      { name: 'Werft/Ausführung', value: 'Ayna Tershanesi , Fethiye' },
      {
        name: 'Konstruktion/Styling',
        value: 'Viking Gemi Insaat Müh., Fatma Kisar',
      },
      { name: 'Indienststellung', value: '2021' },
      { name: 'Heimathafen', value: 'Istanbul' },
      { name: 'Management/Eigner', value: 'Admiral Yatcilik' },
      { name: 'Dieseltank', value: '16 t' },
      { name: 'Trinkwassertank', value: '80 t' },
      { name: 'Motorleistung', value: '2 Motoren à 1.100 HP (Daewoo)' },
      {
        name: 'Generatoren / Leistung',
        value:
          '2 Generatoren à 80 KW (Daewoo) + 2 Generatoren à 33 kw (Kubato)',
      },
      { name: 'Abwassertank', value: '30t' },
      {
        name: 'Abwasseraufbereitung',
        value: 'vollautomatisches Klärsystem (Blue Sea 12.000)',
      },
      { name: 'Stromversorgung', value: 'Elektrik 24/220 Volt tagsüber' },
      {
        name: 'Klimaanlage',
        value:
          'ausgelegt bis 40° C, Navigationsanlage mit SAT NAV, Grenzwellen-Sende- und Empfangsanlage, Radar., Beiboote Joker / je 30 PS, 2 Steuerstände, Rettungsinseln für 60 Personen.',
      },
      { name: 'Crew-Kabinen', value: '1 Kapitäns-Kabine / 6 Crew-Kabinen' },
      { name: 'Crew', value: '5 Personen' },
      {
        name: 'Weitere Highlights',
        value:
          'voll ausgestattete Bordküche, Wäscherei, Kühlräume, Kezeption, Salon, 3 BarsKabinen:  Die 18 Kabinen und 2 Suiten auf der MS Royal Admiral haben Panoramafenster und sind mit einem eigenen franz. Balkon ausgestattet sein. Selbstverständlich verfügen alle Kabinen über Klimaanlage und Bad ensuite. SPA-Bereich mit Sauna, Whirlpool, Massage- und Ruhezonen im unteren Deckbereich, Jacuzzi auf dem Vorderdeck.',
      },
    ],
    body: `
Our new MS Royal Admiral will cause furore and honor her name. A motorsailer in solid wood of this size is is unprecedented worldwide. Size and outstanding interior design of all areas of the ship will create new standards. For our guests in future we attach the highest importance to quality, maritime styling and functionality. 

This is unique on a Gulet: cabins with sea view!
All cabins on the MS Royal Admiral have panoramic windows will be equipped with their own French balcony.
All cabins are air-conditioned and have en-suite bathrooms.

We don’t want to reveal too much...
But what our guests on the MS Royal Admiral will expect is a new dimension.
Even larger out-door areas, a SPA area in the lower deck area, Jacuzzi on the front deck…. 
    `,
    state: 'Funding Phase',
    progress: '70',
    ppDS: '1.00 €',
    noDS: '2,200,000',
    finVolume: '2,200,000 €',
    pofAssets: '5.70',
  },
  {
    id: 8,
    slug: 'kas-akropol-restaurant',
    category: 2,
    images: [
      '/media/marketplace/kas-akropol-1.jpeg',
      '/media/marketplace/kas-akropol-2.jpeg',
      '/media/marketplace/kas-akropol-3.jpeg',
      '/media/marketplace/kas-akropol-4.jpeg',
    ],
    title: 'Kaş Akropol Restaurant',
    features: [
      {
        name: 'Kitchens',
        value: 'Turkish',
      },
      {
        name: 'Those days',
        value: 'Breakfast, Lunch, Dinner',
      },
      {
        name: 'Features ',
        value: 'Reservations, Table Service, Seating ',
      },
    ],
    body: `
    The Acropolis, built on a hill, has a complete sea view from the west. So the sunset can be watched magnificently from here. Appetizers and grill types, pastas, hamburgers menu titles. It is a special case that it is the only restaurant in the Peninsula. The restaurant, built on an area of 1.5 decares, is also a very suitable environment for special events such as weddings and celebrations.
    `,
    state: 'Funding Phase',
    progress: '20',
    ppDS: '1.00 €',
    noDS: '1,700,000',
    finVolume: '1,700,000 €',
    pofAssets: '3.80',
  },
]
