import {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react'

export interface IUserContext {
  iat: number | null
  id: number | null
  email: string | null
  token: string | null
  username: string | null
}

const initialState = {
  iat: null,
  id: null,
  email: null,
  token: null,
  username: null,
}

export const UserContext = createContext<any>(initialState)

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<IUserContext>(initialState)

  useEffect(() => {
    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const loginUser = useCallback((response: any): void => {
    // postLogin promise
    const obj: IUserContext = {
      iat: Date.now(),
      id: response.id,
      email: response.email,
      token: '0x000000',
      username: response.name,
    }
    localStorage.setItem('web-user', JSON.stringify(obj))
    setUser(obj)
  }, [])

  const logoutUser = useCallback((): void => {
    setUser(initialState)
    localStorage.removeItem('web-user')
  }, [])

  const verifyToken = useCallback((): boolean => {
    if (user.token) {
      if (expirationControl(user.iat || 0)) return true
      setUser(initialState)
      return false
    }

    const storedUser = localStorage.getItem('web-user')

    if (storedUser) {
      const storageResponse: IUserContext = JSON.parse(storedUser)
      if (expirationControl(storageResponse.iat || 0)) {
        setUser(storageResponse)
        return true
      }
      localStorage.removeItem('web-user')
      return false
    }
    return false
  }, [user])

  const isAuthenticated: boolean = user !== initialState

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user,
        setUser,
        loginUser,
        verifyToken,
        isAuthenticated,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserValue = () => useContext(UserContext)

const expirationControl = (initial: number): boolean => {
  const exp: number = initial + 3600000 * 4
  if (Date.now() >= exp) return false
  return true
}
