import { FC, ReactElement } from 'react'
import './IconCard.scss'

interface IconCardProps {
  title: string
  icon: ReactElement
  description: string
}

const IconCard: FC<IconCardProps> = ({
  title,
  icon,
  description,
}): ReactElement => {
  return (
    <div className="icon-card">
      {icon}
      <div className="content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default IconCard
