import { useState } from 'react'

const tabNames: Array<string> = ['Fiat', 'Crypto']

const Withdraw = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="grid grid-cols-1 gap-2 h-100 px-4">
      <div className="container bg-white marketplace mt-5">
        <nav className="flex flex-col justify-start sm:flex-row">
          {tabNames.map((el, index) => (
            <button
              key={el}
              className={`tab-text ${activeTab === index && 'tab-active'}`}
              onClick={() => setActiveTab(index)}
              type="button"
            >
              {el}
            </button>
          ))}
        </nav>
      </div>
      <div className="container bg-white marketplace mt-5">
        {tabNames.map(
          (market, marketIndex) =>
            activeTab === Number(marketIndex) && (
              <div
                key={market}
                className="card-container grid grid-cols-4 gap-12"
              >
                {market}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default Withdraw
