import { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useUserValue } from '../../contexts/UserContext'

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useUserValue()

  if (!isAuthenticated) return <Navigate to="/login" replace />
  return children
}

export default RequireAuth
