import axios from 'axios'
import useSWR from 'swr'

const API_ENDPONT = 'https://fake-api.bkinvestment.com.tr'
const REGISTER = `${API_ENDPONT}/register`
const LOGIN = `${API_ENDPONT}/login`
const KYC = `${API_ENDPONT}/kyc`
const INVESTMENT_NEW = `${API_ENDPONT}/investments`
const INVESTMENT_LIST = `${API_ENDPONT}/investments/`
const USER_DATA = `${API_ENDPONT}/users`

export const fetcher = async (url: string, token: string) => {
  return axios
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
}

export const postLogin = async (postData: any) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post<any>(LOGIN, postData)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const postRegister = async (postData: any) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post<any>(REGISTER, postData)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const postKyc = async (postData: any) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post<any>(KYC, postData)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const postNewInvestment = async (postData: any) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post<any>(INVESTMENT_NEW, postData)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const useDataUserInvestments = () => {
  const { data, error } = useSWR(INVESTMENT_LIST, fetcher, {
    revalidateOnFocus: false,
  })
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useDataUser = (id: any) => {
  const { data, error } = useSWR(`${USER_DATA}/${id}`, fetcher, {
    revalidateOnFocus: false,
  })
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}
