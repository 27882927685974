import { FC, ReactElement, CSSProperties } from 'react'
import './MarketCard.scss'

export interface ProgressBarCss extends CSSProperties {
  width: string
}

interface MarketcardProps {
  title?: string
  children?: string
  imgSrc: string
  imgAlt?: string
  route: string
  tabIndex: number
  state: string
  progress: string
  ppDS: string
  noDS: string
  finVolume: string
  pofAssets: string
}

const Marketcard: FC<MarketcardProps> = ({
  title = '',
  children = '',
  imgSrc,
  imgAlt,
  route,
  tabIndex = 0,
  state,
  progress,
  ppDS,
  noDS,
  finVolume,
  pofAssets,
}): ReactElement => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-row rounded-lg bg-white shadow-lg">
        <div>
          <img
            className=" w-full h-full object-cover rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={imgSrc}
            alt="imgAlt"
          />
        </div>
        <div className="p-6 flex flex-col justify-start">
          <div className="marketcard-content divide-y">
            <h3 className="marketcard-title">
              <span>{title}</span>
            </h3>
            <div className="grid grid-cols-2 gap-4 pt-2">
              <h3 className="text-left font-bold text-orange-500">{state}</h3>
              <div className="grid grid-cols-3 gap-4 text-right items-center">
                <div className="w-full bg-gray-200 rounded-lg h-2.5 dark:bg-blue-900 col-span-2">
                  <div
                    className="bg-orange-600 h-2.5 rounded-full"
                    style={
                      {
                        width: `${progress}%`,
                      } as ProgressBarCss
                    }
                  />
                </div>
                <text className=" font-bold text-blue-900 text-sm">
                  {progress} <text className="text-sm">%</text>
                </text>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 justify-center pt-2 pb-2">
              <div className="col-span-2 text-left">
                <text className="font-light">Price per Digital Share</text>
              </div>
              <div className="text-right">
                <text className="font-bold ">{ppDS}</text>
              </div>
              <div className="col-span-2 text-left">
                <text className="font-light">Number of Digital Shares</text>
              </div>
              <div className="text-right">
                <text className="font-bold">{noDS}</text>
              </div>
              <div className="col-span-2 text-left">
                <text className="font-light">Financing Volume</text>
              </div>
              <div className="text-right">
                <text className="font-bold">{finVolume}</text>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 pt-2">
              <h4 className="text-left font-bold text-orange-500 text-sm">
                Historical performance of assets p.a.*
              </h4>
              <text className="text-right font-bold text-blue-900 text-2xl">
                {pofAssets} <text className="text-sm">%</text>
              </text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Marketcard
