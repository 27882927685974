import { FC, ReactElement, useRef } from 'react'

// TODO: Extract styles to scss file
// TODO: Implement theme style on it
interface FaqProduct {
  title: string
  liItem: string[]
}

const ProductFAQ: FC<FaqProduct> = ({
  title = '',
  liItem = [],
}): ReactElement => {
  const tabDetail = useRef<any>(null)
  const toggleItem = () => {
    tabDetail.current.style['max-height'] === '100vh'
      ? (tabDetail.current.style['max-height'] = '0vh')
      : (tabDetail.current.style['max-height'] = '100vh')
    // tabDetail?.current?.classList?.toggle('max-h-0')
  }
  return (
    <div className="flex flex-col p-1 shadow rounded-lg overflow-hidden my-2 hover:shadow-lg w-full">
      <div
        className="flex p-3 justify-between bg-white w-full cursor-pointer"
        onClick={toggleItem}
        onKeyPress={toggleItem}
        tabIndex={0}
        role="button"
      >
        <span className="text-gray-700 text-xl">{title}</span>
        <div className="rounded-lg border border-grey w-7 h-7 flex items-center justify-center test">
          <svg
            aria-hidden="true"
            className=""
            data-reactid="266"
            fill="none"
            height="24"
            stroke="#606F7B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </div>
      </div>
      <div
        className="tab-content transition-all duration-700 max-h-0"
        ref={tabDetail}
      >
        <div className="pl-8 pr-8 pb-5 text-gray-700">
          <ul className="pl-4">
            {liItem.map(text => (
              <li className="pb-2">{text}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProductFAQ
