import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { postRegister } from '../../data/connections'
import PageHero from '../shared/PageHero'

/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO: floating labels
const Register = () => {
  const [errors, setErrors] = useState(null)
  const ibName = useRef<any>(null)
  const ibEmail = useRef<any>(null)
  const ibMobile = useRef<any>(null)
  const ibPassword = useRef<any>(null)
  const ibPasswordRe = useRef<any>(null)
  const navigate = useNavigate()

  const handleRegister = () => {
    if (errorControl()) {
      postRegister({
        name: ibName.current.value,
        email: ibEmail.current.value,
        phone: ibMobile.current.value,
        password: ibPassword.current.value,
      })
        .then(res => navigate('/verification'))
        .catch(err => setErrors(err))
    }
  }
  const errorControl = () => {
    if (
      ibName.current.value &&
      ibEmail.current.value &&
      ibMobile.current.value &&
      ibPassword.current.value &&
      ibPasswordRe.current.value
    ) {
      if (ibPasswordRe.current.value === ibPassword.current.value) {
        return true
      }
      return false
    }
    return false
  }
  return (
    <section className="text-gray-600 body-font space-y-10 mb-20">
      <PageHero title="Sign Up" narrow bgClassName="bg-team">
        <p className="leading-relaxed">Register a new account.</p>
      </PageHero>

      <div className="w-full flex flex-col sm:justify-center items-center sm:pt-0">
        <div className="w-full sm:max-w-md p-5 mx-auto">
          {errors ? (
            <div className="p-2">
              <div className="inline-flex items-center bg-white leading-none text-pink-600 rounded-full p-2 shadow text-teal text-bold text-lg">
                <span className="inline-flex bg-pink-600 text-white rounded-full h-6 px-3 justify-center items-center">
                  Error
                </span>
                <span className="inline-flex px-2">Incorrect information.</span>
              </div>
            </div>
          ) : null}

          <form>
            {/* Name */}
            <div className="mb-4">
              <label className="block font-bold">Name</label>
              <input
                type="text"
                ref={ibName}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Mobile */}
            <div className="mb-4">
              <label className="block font-bold">Mobile</label>
              <input
                type="tel"
                value="+90 "
                ref={ibMobile}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Email */}
            <div className="mb-4">
              <label className="block font-bold">Email Address</label>
              <input
                type="email"
                ref={ibEmail}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Password */}
            <div className="mb-4">
              <label className="block font-bold">Password</label>
              <input
                type="password"
                ref={ibPassword}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            {/* Password 2 */}
            <div className="mb-4">
              <label className="block font-bold">Password Again</label>
              <input
                type="password"
                ref={ibPasswordRe}
                className="py-2 px-3 border border-gray-300 focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mt-6">
              <button
                type="button"
                className="w-full inline-flex 
            items-center justify-center px-4 py-2 
            bg-orange-500 border border-transparent rounded-md
            font-semibold capitalize text-white 
            hover:bg-orange-700
            active:bg-orange-700
            focus:outline-none 
            focus:border-orange-700
            focus:ring 
            focus:ring-orange-200
            disabled:opacity-25 transition"
                onClick={handleRegister}
              >
                Sign Up
              </button>
            </div>
            <div className="mt-6 text-center">
              <Link to="/login" className="hover:underline">
                Already registered? Log in here.
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Register
