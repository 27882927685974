/* eslint-disable no-nested-ternary */
import { FC } from 'react'

const OfferSteps: FC<any> = ({ currentStep }) => {
  return (
    <div className="container flex">
      <SingleStep number="1" text="Overview" active={currentStep >= 1} />
      <StepBorder width={currentStep > 1 ? 'w-full' : 'w-1/2'} />
      <SingleStep number="2" text="Person" active={currentStep >= 2} />
      <StepBorder
        width={currentStep > 2 ? 'w-full' : currentStep === 2 ? 'w-1/2' : 'w-0'}
      />
      <SingleStep number="3" text="Confirmation" active={currentStep >= 3} />
      <StepBorder
        width={currentStep > 3 ? 'w-full' : currentStep === 3 ? 'w-1/2' : 'w-0'}
      />
      <SingleStep number="4" text="Result" active={currentStep >= 4} />
    </div>
  )
}

export default OfferSteps

const SingleStep: FC<any> = ({ number, text, active = false }) => (
  <div className="flex-1 text-center items-center">
    <div
      className={`w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center ${
        active ? 'bg-orange-600' : 'bg-white border-2 border-gray-300'
      }`}
    >
      <span
        className={`text-center w-full ${
          active ? 'text-white-300' : 'text-gray-600'
        }`}
      >
        {number}
      </span>
    </div>
    <p>{text}</p>
  </div>
)

const StepBorder: FC<any> = ({ width }) => (
  <div className="w-1/6 align-center items-center align-middle content-center flex">
    <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
      <div
        className={`bg-orange-400 text-xs leading-none py-1 text-center text-gray-600 rounded ${width}`}
      />
    </div>
  </div>
)

/* bg-white border-2 border-gray-300
bg-green-600 */
