/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

const AboutText2: FC = () => {
  return (
    <div className="container mx-auto grid md:grid-cols-2 gap-20 ">
      <div className="block p-12 rounded-lg shadow-lg bg-white divide-y-2 divide-dotted divide-gray">
        <div className="pt-2">
          <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
            Explore
          </h5>
          <p className="text-gray-700 text-base mb-4">
            Learn more about how we help people invest and manage their money
            with our various services and programs.
          </p>
        </div>
        <div className="pt-2">
          <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
            Start investing
          </h5>
          <p className="text-gray-700 text-base mb-4">
            We`ll provide you with all the information you need to make an
            informed investment decision. You`ll know what`s best for your needs
            and how much risk is right for you.
          </p>
        </div>
      </div>
      <div className="grid grid-rows-2 gap-4">
        <span className="title-font sm:text-5xl text-4xl font-light text-orange-500">
          Maximum flexibility
        </span>
        <p className="">
          You invest 100 % digitally. You put all of your money into digital
          investments. Intermediaries are eliminated with the use of blockchain
          technology, saving up to 80% of the costs of a traditional securities
          offering. Your digital shares can also be traded for free at any time
          on our over-the-counter secondary market, giving you maximum
          flexibility.
        </p>
      </div>
    </div>
  )
}

export default AboutText2
