import { FC, ReactElement, useEffect, useState, CSSProperties } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import { investments as tempData } from '../../../data/marketplace/investments'
import GalleryBand from './GalleryBand'
import ProductFAQ from './ProductFAQ'
import Sidebar from './Sidebar'

export interface ProgressBarCss extends CSSProperties {
  width: string
}
const Product: FC = (): ReactElement => {
  const [product, setProduct] = useState<any>(null)
  const params = useParams()

  useEffect(() => {
    const searchResult = tempData.find(el => el.slug === params.slug)

    if (searchResult) setProduct(searchResult)
  }, [params.slug])

  // TODO: Implement 404 page.
  if (!product) return <div>Failed to load item</div>

  return (
    <div className="container-fluid SingleMarketItem">
      <div className="container mx-auto my-5 py-4 justify-center text-center">
        <h1 className="text-2xl text-gray-700 text-left leading-none">
          <Button className="mr-5" route="/marketplace">
            Back
          </Button>
          {product.title}
        </h1>
      </div>

      <GalleryBand images={product.images} />

      <div className="container mx-auto my-5 py-5 justify-center text-left">
        <div className="flex">
          <div className="container basis-3/4 p-3 content-center align-center">
            <div className="text-grey-darkest">
              <div className="grid grid-cols-3 my-4">
                <div className="col-start-1">
                  <h2 className="text-3xl align-self-left mb-2 text-orange-700 font-bold">
                    {product.title}
                  </h2>
                </div>
                <div className="w-full col-start-3">
                  <div className="flex justify-between mb-1">
                    <span className="text-base font-medium text-blue-700">
                      {product.state}
                    </span>
                    <span className="text-sm font-medium text-blue-700">
                      {product.progress}%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-orange-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={
                        {
                          width: `${product.progress}%`,
                        } as ProgressBarCss
                      }
                    />
                  </div>
                </div>
              </div>

              {product.body}
            </div>

            <div className="flex flex-col my-6">
              <h2 className="text-lg align-self-left mb-2 text-orange-700 font-bold">
                FAQs
              </h2>

              <ProductFAQ
                title="Who can participate?"
                liItem={[
                  'Each client need to register before using our platform.',
                  'Each client will be checked before beeing registered as well as it is possible to ensure, that you are a person contractually capable.',
                  'Different countries have different laws in these businesses. You cant’t participate or register, when the law of your country does not allow it. We help you, if you have questions in these cases.',
                ]}
              />
            </div>

            <div className="flex flex-col">
              <h2 className="text-lg align-self-left mb-2 text-orange-700 font-bold">
                Location
              </h2>

              <div className="flex w-full inset-0 py-5">
                <iframe
                  width="100%"
                  height="500"
                  frameBorder="0"
                  marginHeight={0}
                  marginWidth={0}
                  title="map"
                  scrolling="no"
                  className="rounded-lg"
                  src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=Beyaz Dünya İş Merkezi&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                />
              </div>
            </div>
          </div>
          <div className="container basis-1/4 p-3">
            <Sidebar
              features={product.features}
              state={product.state}
              progress={product.progress}
              ppDS={product.ppDS}
              noDS={product.noDS}
              finVolume={product.finVolume}
              pofAssets={product.pofAssets}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
