import { Link, Outlet } from 'react-router-dom'
import SidebarMenu from './SidebarMenu'
import { useUserValue } from '../../contexts/UserContext'
import { useDataUser } from '../../data/connections'

const Account = () => {
  const { user } = useUserValue()
  const { data, isLoading, isError } = useDataUser(user.id)

  if (isLoading) return <div>Loading</div>
  if (isError) return <div>Hata</div>

  return (
    <div className="flex w-full min-h-screen-2/3">
      <div className="sidebar min-h-screen bg-blue-900 drop-shadow-lg w-1/5 py-10 text-white">
        <SidebarMenu />
      </div>
      <div className="main w-4/5">
        <div className="flex flex-col flex-nowrap w-full shadow-lg mb-2 p-2">
          <div className="flex">
            <p className="font-thin mr-2">{user.username}</p>
            <Link className="text-orange-600" to="/account/settings">
              KYC {data.kyc}
            </Link>
          </div>
          <div className="text-xs text-gray-500">
            <span className="text-gray-600">Last login: </span> 03/02/2022
            16:16:44
            <span className="ml-2 text-gray-600">IP: </span> 91.93.2.1
          </div>
        </div>
        <Outlet context={data} />
      </div>
    </div>
  )
}

export default Account
