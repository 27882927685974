import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

const Layout: FC = () => (
  <div className="App">
    <Header />

    <main>
      <Outlet />
    </main>

    <Footer />
  </div>
)

export default Layout
