import { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import './CarouselCard.scss'

interface CarouselCardProps {
  title?: string
  children?: string
  imgSrc: string
  imgAlt?: string
  route: string
  tabIndex: number
}

const CarouselCard: FC<CarouselCardProps> = ({
  title = '',
  children = '',
  imgSrc,
  imgAlt,
  route,
  tabIndex = 0,
}): ReactElement => {
  const navigate = useNavigate()
  const singleImage = title === '' && children === ''

  return (
    <div
      role="link"
      tabIndex={tabIndex}
      className={`card-carousel ${singleImage ? 'h-[200px]' : 'h-[400px]'}`}
      onClick={() => navigate(route)}
      onKeyDown={() => navigate(route)}
    >
      <div
        className={`card-image-wrapper ${
          singleImage ? 'rounded-lg row-span-6' : 'rounded-t-lg row-span-3'
        }`}
      >
        <img src={imgSrc} className="card-image" alt={imgAlt} />
      </div>
      {(!!title || !!children) && (
        <div className="card-content flex flex-col justify-evenly">
          <h3 className="card-title">{title}</h3>
          <p className="card-text">{children.substring(0, 150)} ...</p>
        </div>
      )}
    </div>
  )
}

export default CarouselCard
