import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useUserValue } from '../../contexts/UserContext'
import './Navbar.scss'

const transition = {
  transitionDuration: '0ms',
  transitionDelay: '0ms',
  animationDuration: '300ms',
  transitionTimingFunction: 'ease',
}

const Navbar: FC = (): ReactElement => {
  const { isAuthenticated } = useUserValue()

  return (
    <nav className="navbar">
      <Link className="navbar-brand" to="/">
        <img src="/media/logo.png" alt="Logo" />
      </Link>

      <button className="navbar-toggle" type="button">
        <i className="fa fa-bars" />
      </button>

      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="active">
            <Link to="/">Home</Link>
          </li>
          <li className="dropdown multi-level">
            <Link className="dropdown-toggle" data-toggle="dropdown" to="/">
              Portfolio
              <span className="caret" />
            </Link>
            <ul className="dropdown-menu fade-bottom" style={transition}>
              <li className="">
                <Link to="/">Hotels</Link>
              </li>
              <li className="">
                <Link to="/">Shopping Centers</Link>
              </li>
              <li className="">
                <Link to="/">Luxury Yachts</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="marketplace">Marketplace</Link>
          </li>
          <li className="dropdown multi-level">
            <Link className="dropdown-toggle" data-toggle="dropdown" to="/">
              Company
              <span className="caret" />
            </Link>
            <ul className="dropdown-menu fade-bottom" style={transition}>
              <li className="">
                <Link to="/about">About Us</Link>
              </li>
              <li className="">
                <Link to="/team">Our Team</Link>
              </li>
              <li className="">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="">
                <Link to="/press">Press</Link>
              </li>
            </ul>
          </li>
          {isAuthenticated ? (
            <li>
              <Link to="/account">My Account</Link>
            </li>
          ) : (
            <>
              <li>
                <Link to="login">Login</Link>
              </li>
              <li>
                <Link to="register">Register</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
