import { useState } from 'react'
import {
  faChevronLeft,
  faChevronRight,
  faCopy,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const tabNames: Array<string> = ['Fiat', 'Crypto']

interface Bank {
  name: string
  type: string
  logo: string
  accountName: string
  IBAN: string
}

interface Crypto {
  name: string
  type: string
  logo: string
  network: string
}

const banks: Array<Bank> = [
  {
    name: 'Ziraat Bank',
    type: 'Direct & EFT',
    logo: 'https://www.trbinance.com/static/bankicon/ziraat.png',
    accountName: 'BK Investment AS',
    IBAN: 'TR100001001166917295525001',
  },
  {
    name: 'AKBANK',
    type: 'Direct & EFT',
    logo: 'https://www.trbinance.com/static/bankicon/akbank.png',
    accountName: 'BK Investment AS',
    IBAN: 'TR100001001166917295525001',
  },
  {
    name: 'VakıfBank',
    type: 'Direct & EFT',
    logo: 'https://www.trbinance.com/static/bankicon/vakif.png',
    accountName: 'BK Investment AS',
    IBAN: 'TR100001001166917295525001',
  },
  {
    name: 'Fibabanka',
    type: 'Direct & EFT',
    logo: 'https://www.trbinance.com/static/bankicon/fibabank.png',
    accountName: 'BK Investment AS',
    IBAN: 'TR100001001166917295525001',
  },
  {
    name: 'Others',
    type: 'EFT',
    logo: 'https://www.trbinance.com/static/dist/img/20567fd.png',
    accountName: 'BK Investment AS',
    IBAN: 'TR100001001166917295525001',
  },
]

const cryptos: Array<Crypto> = [
  {
    name: 'Bitcoin',
    type: '7/24 on Chain',
    logo: 'https://assets.coingecko.com/coins/images/1/thumb_2x/bitcoin.png',
    network: 'BTC',
  },
  {
    name: 'Ethereum',
    type: '7/24 on Chain',
    logo: 'https://assets.coingecko.com/coins/images/279/thumb_2x/ethereum.png',
    network: 'ETC20',
  },
  {
    name: 'BKEUR',
    type: '7/24 on Chain',
    logo: 'https://assets.coingecko.com/coins/images/19018/small/rTuKxuC.png',
    network: 'ETC20',
  },
  {
    name: 'USDC',
    type: '7/24 on Chain',
    logo: 'https://assets.coingecko.com/coins/images/6319/thumb_2x/USD_Coin_icon.png',
    network: 'ETC20',
  },
]

const Deposit = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeBank, setActiveBank] = useState(banks[0])
  const [showBankDetails, setShowBankDetails] = useState(false)
  const [activeCrypto, setActiveCrypto] = useState(cryptos[0])
  const [showCryptoDetails, setShowCryptoDetails] = useState(false)

  const handleBankDetails = (bank: Bank): void => {
    setActiveBank(bank)
    setShowBankDetails(true)
  }

  const handleBankBack = (): void => {
    setShowBankDetails(false)
  }

  const handleCryptoDetails = (crypto: Crypto): void => {
    setActiveCrypto(crypto)
    setShowCryptoDetails(true)
  }

  const handleCryptoBack = (): void => {
    setShowCryptoDetails(false)
  }

  const renderBankTab = () => {
    return (
      <>
        {!showBankDetails ? (
          <div className="bg-white font-semibold rounded-lg border shadow-lg p-5 animate-fade-in">
            <h2 className="text-gray-600 text-xl mb-4">Our Bank Accounts</h2>
            <div className="card-container grid grid-cols-2 gap-12 content-center">
              {banks.map((bank, bankIndex) => (
                <button
                  type="button"
                  onClick={() => handleBankDetails(bank)}
                  key={bank.name}
                  className="border p-2 flex items-center justify-between last:col-span-2"
                >
                  <img src={bank.logo} className=" h-1/2 mr-2" alt="" />
                  <span className="border-l p-2 text-xs">{bank.type}</span>
                  <span className="ml-2 flex items-center text-gray-400 text-xs">
                    <FontAwesomeIcon icon={faChevronRight} size="1x" />
                  </span>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="bg-white rounded-lg border shadow-lg p-5 animate-fade-in">
              <button
                type="button"
                onClick={() => handleBankBack()}
                key={activeBank.name}
                className="border w-full p-2 flex items-center justify-between last:col-span-2"
              >
                <span className="mr-4 flex items-center text-gray-400 text-xs">
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                </span>
                <img
                  src={activeBank.logo}
                  className="max-w-xs h-1/2 mr-2"
                  alt=""
                />
                <span className="border-l p-2 text-xs">{activeBank.type}</span>
              </button>
              <div className="card-container grid grid-cols-1 gap-12 content-center">
                <ul className="w-full mt-4">
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Daily Limit:{' '}
                    </span>
                    <span>1,000,000 TRY</span>
                  </li>
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Monthly Limit:
                    </span>
                    <span>50,000,000 TRY</span>
                  </li>
                  <hr />
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Remaining Daily Limit:{' '}
                    </span>
                    <span>950,000 TRY</span>
                  </li>
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Remaining Monthly Limit:{' '}
                    </span>
                    <span>49,950,000 TRY</span>
                  </li>
                  <hr />
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">Hesap Adı:</span>
                    <span>
                      BK Investment A.Ş.{' '}
                      <button type="button">
                        <FontAwesomeIcon icon={faCopy} size="1x" />
                      </button>
                    </span>
                  </li>
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">IBAN:</span>
                    <span>
                      TR480006256955645439715375{' '}
                      <button type="button">
                        <FontAwesomeIcon icon={faCopy} size="1x" />
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-yellow-50 rounded-lg border shadow-lg p-5 animate-fade-in">
              <h2 className="text-lg mb-2">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faInfoCircle}
                  size="1x"
                />
                Please read before making any transaction.
              </h2>
              <ul className="list-decimal list-inside">
                <li>
                  You can send Turkish Lira only from your personal bank account
                  registered in your own name, by money order or EFT.
                </li>
                <li>
                  You can deposit 24/7 with your {activeBank.name} account.
                </li>
                <li>
                  You can make deposits to our {activeBank.name} account from
                  all banks during bank working hours.
                </li>
                <li>
                  Transfers made by ATM or credit card will not be accepted as
                  it is not possible to confirm the sender information.
                </li>
                <li>
                  There is no minimum deposit limit of Turkish Lira, you can
                  make your TRY deposits within your limits.
                </li>
                <li>
                  After your first Turkish Lira deposit, your crypto withdrawals
                  will be disabled for 48 hours.{' '}
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="bg-white col-span-2 rounded-lg border shadow-lg p-5 animate-fade-in place-self-end">
          <h2 className="text-orange-600 text-xl mb-4">History</h2>

          <table className="table-fixed w-full">
            <thead>
              <tr className="text-left">
                <th>Currency</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Euro</td>
                <td>Completed</td>
                <td>4000</td>
                <td>09/07/2021</td>
                <td>Bank Transfer</td>
              </tr>
              <tr>
                <td>BTC</td>
                <td>Completed</td>
                <td>1.32</td>
                <td>19/06/2021</td>
                <td>Blockchain Transaction</td>
              </tr>
              <tr>
                <td>Euro</td>
                <td>Completed</td>
                <td>1000</td>
                <td>09/01/2021</td>
                <td>Bank Transfer</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const renderCryptoTab = () => {
    return (
      <>
        {!showCryptoDetails ? (
          <div className="bg-white font-semibold rounded-lg border shadow-lg p-5 animate-fade-in">
            <h2 className="text-gray-600 text-xl mb-4">Our Crypto Accounts</h2>
            <div className="card-container grid grid-cols-2 gap-12 content-center">
              {cryptos.map((crypto, cryptoIndex) => (
                <button
                  type="button"
                  onClick={() => handleCryptoDetails(crypto)}
                  key={crypto.name}
                  className="border p-2 flex items-center justify-between"
                >
                  <img src={crypto.logo} className=" h-1/2 mr-2" alt="" />
                  <span className="border-l p-4 text-xs">{crypto.type}</span>
                  <span className="ml-2 flex items-center text-gray-400 text-xs">
                    <FontAwesomeIcon icon={faChevronRight} size="1x" />
                  </span>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="bg-white rounded-lg border shadow-lg p-5 animate-fade-in">
              <button
                type="button"
                onClick={() => handleCryptoBack()}
                key={activeCrypto.name}
                className="border w-full p-2 flex items-center justify-between last:col-span-2"
              >
                <span className="mr-4 flex items-center text-gray-400 text-xs">
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                </span>
                <img
                  src={activeCrypto.logo}
                  className="max-w-xs h-1/2 mr-2"
                  alt=""
                />
                <span className="border-l p-2 text-xs">
                  {activeCrypto.type}
                </span>
              </button>
              <div className="card-container grid grid-cols-1 gap-12 content-center">
                <ul className="w-full mt-4">
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Daily Limit:{' '}
                    </span>
                    <span>none</span>
                  </li>
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">
                      Monthly Limit:
                    </span>
                    <span>none</span>
                  </li>
                  <hr />
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">Network:</span>
                    <span>
                      {activeCrypto.network}
                      <button type="button">
                        <FontAwesomeIcon
                          icon={faCopy}
                          className="ml-2"
                          size="1x"
                        />
                      </button>
                    </span>
                  </li>
                  <li className="flex justify-between my-4 w-full">
                    <span className="text-gray-700 font-bold">Address:</span>
                    <span>
                      0x62247ca8be6513aa4ff6a317c5c97ebe724af322
                      <button type="button" className="ml-2">
                        <FontAwesomeIcon icon={faCopy} size="1x" />
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-yellow-50 rounded-lg border shadow-lg p-5 animate-fade-in">
              <h2 className="text-lg mb-2">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faInfoCircle}
                  size="1x"
                />
                Please read before making any transaction.
              </h2>
              <ul className="list-decimal list-inside">
                <li>
                  You can send Turkish Lira only from your personal bank account
                  registered in your own name, by money order or EFT.
                </li>
                <li>
                  You can deposit 24/7 with your {activeCrypto.name} account.
                </li>
                <li>
                  You can make deposits to our {activeCrypto.name} account from
                  all banks during bank working hours.
                </li>
                <li>
                  Transfers made by ATM or credit card will not be accepted as
                  it is not possible to confirm the sender information.
                </li>
                <li>
                  There is no minimum deposit limit of Turkish Lira, you can
                  make your TRY deposits within your limits.
                </li>
                <li>
                  After your first Turkish Lira deposit, your crypto withdrawals
                  will be disabled for 48 hours.{' '}
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="bg-white col-span-2 rounded-lg border shadow-lg p-5 animate-fade-in place-self-end">
          <h2 className="text-orange-600 text-xl mb-4">History</h2>

          <table className="table-fixed w-full">
            <thead>
              <tr className="text-left">
                <th>Currency</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Euro</td>
                <td>Completed</td>
                <td>4000</td>
                <td>09/07/2021</td>
                <td>Crypto Transfer</td>
              </tr>
              <tr>
                <td>BTC</td>
                <td>Completed</td>
                <td>1.32</td>
                <td>19/06/2021</td>
                <td>Blockchain Transaction</td>
              </tr>
              <tr>
                <td>Euro</td>
                <td>Completed</td>
                <td>1000</td>
                <td>09/01/2021</td>
                <td>Crypto Transfer</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <div className="min-h-100 px-4">
      <div className="grid grid-cols-1 container bg-white marketplace mt-5">
        <nav className="flex flex-col justify-start sm:flex-row">
          {tabNames.map((el, index) => (
            <button
              key={el}
              className={`tab-text ${
                activeTab === index &&
                'text-orange-500 border-b-2 border-orange-500'
              }`}
              onClick={() => setActiveTab(index)}
              type="button"
            >
              {el}
            </button>
          ))}
        </nav>
      </div>

      <div className="container grid grid-cols-2 gap-4 bg-white marktext-gray-600 py-3 px-6 block focus:outline-none font-mediumetplace mt-5">
        {activeTab === 0 ? renderBankTab() : renderCryptoTab()}
      </div>
    </div>
  )
}

export default Deposit
