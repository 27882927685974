import { useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignInAlt,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import AssetsChart from './AssetsChart'
import Button from '../../../components/Button'

const Wallet = () => {
  const [personAmouth, setPersonAmouth] = useState(false)
  const data: any = useOutletContext()
  return (
    <>
      <div className="px-4">
        {/* TODO: implement warning component */}
        {/* TODO: show if user on KYC level 0 */}
        {data.kyc === 0 && (
          <div className="grid grid-cols-12 items-center bg-orange-100 leading-none text-white rounded-lg py-2 px-4 my-4 shadow cursor-default w-full p-2">
            <span className="col-span-2 inline-flex bg-white text-orange-400 shadow-md rounded-full h-6 p-4 justify-center items-center text-xl">
              KYC level 1
            </span>
            <Link
              to="/account/settings"
              className="col-span-8 inline-flex px-2 text-lg"
            >
              In order to deposit your account, please complete KYC level 1.
            </Link>
            <button
              type="button"
              className="col-span-2 text-gray-200 hover:text-gray-200 justify-self-end"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </button>
          </div>
        )}

        {/* TODO: show if user on KYC level 1 */}
        {data.kyc === 1 && (
          <div className="grid grid-cols-12 items-center bg-orange-100 leading-none text-white rounded-lg py-2 px-4 my-4 shadow cursor-default w-full p-2">
            <span className="col-span-2 inline-flex bg-white text-orange-400 shadow-md rounded-full h-6 p-4 justify-center items-center text-xl">
              KYC level 2
            </span>
            <Link
              to="/account/settings"
              className="col-span-8 inline-flex px-2 text-lg"
            >
              In order to withdraw from your account, please complete KYC level
              2.
            </Link>
            <button
              type="button"
              className="col-span-2 text-gray-200 hover:text-gray-200 justify-self-end"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-8 h-100 p-4">
        <div className="grid grid-cols-1 gap-4 bg-white font-semibold text-center rounded-lg border shadow-lg p-5">
          <h2 className="text-gray-600 text-xl mb-4 text-left">My Balance</h2>
          <div className="shadow-md py-5 h-28 rounded w-56 bg-blue-50 text-center opacity-50 md:border-solid md:border-2 md:border-blue-800 mx-auto">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/1200px-Ethereum-icon-purple.svg.png"
              className="h-16 mx-auto"
              alt=""
            />
            <div>
              <span className="font-mono bg-white rounded">
                0x3e622535...435345345
              </span>
            </div>
          </div>
          <div className="mt-3 flex justify-center items-center">
            <h1 className="text-3xl text-gray-700 mx-2">
              €{personAmouth ? data.credit.toFixed(2) : '*****'}
            </h1>
            <button
              type="button"
              onClick={() => setPersonAmouth(prev => !prev)}
            >
              <img src="/media/icons8-eye-64.png" alt="" className="h-8" />
            </button>
          </div>

          <h3 className="text-xl text-gray-400">Total Balance</h3>
          <div className="grid grid-cols-2 gap-4 h-10">
            <Button route="/account/deposit" primary disabled={data.kyc < 1}>
              <span>
                <FontAwesomeIcon icon={faSignInAlt} size="1x" /> Deposit
              </span>
            </Button>
            <Button route="/account/withdraw" disabled={data.kyc < 2}>
              <span>
                <FontAwesomeIcon icon={faSignOutAlt} size="1x" /> Withdraw
              </span>
            </Button>
          </div>
        </div>
        <div className="bg-white font-semibold rounded-lg border shadow-lg p-5">
          <h2 className="text-gray-600 text-xl mb-2">My Assets</h2>
          <div className="h-96">
            <AssetsChart />
          </div>
        </div>
      </div>
    </>
  )
}

export default Wallet
