/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

const AboutText: FC = () => {
  return (
    <div className="container mx-auto items-center grid md:grid-cols-2 gap-20">
      <div className="grid grid-rows-3 gap-4">
        <span className="title-font sm:text-5xl text-4xl font-light text-gray-900">
          Capital requirements
        </span>
        <p className="row-span-2">
          We open up investments that were previously inaccessible to private
          investors due to the significant purchase prices and corresponding
          capital requirements.
        </p>
      </div>
      <div className="flex justify-end">
        <div className="block p-12 rounded-lg shadow-lg bg-white">
          <h4 className="text-3xl font-medium leading-tight mt-0 mb-2 text-orange-500">
            15.000+{' '}
            <span className="text-gray-900 text-lg leading-tight font-medium mb-2">
              ASSETS
            </span>
          </h4>

          <h4 className="text-3xl font-medium leading-tight mt-0 mb-2 text-orange-500">
            10.000+{' '}
            <span className="text-gray-900 text-lg leading-tight font-medium mb-2">
              CUSTOMERS
            </span>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default AboutText
