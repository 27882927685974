import { FC, ReactElement } from 'react'

interface SidebarFeatureProps {
  name: string
  value: string
}

const SidebarFeature: FC<SidebarFeatureProps> = ({
  name,
  value,
}): ReactElement => {
  return (
    <div className="flex justify-between my-2 py-1 hover:bg-orange-100 hover:bg-opacity-20 w-full px-4">
      <span className="font-medium text-blue-900 text-base text-left">
        {name}
      </span>
      <span className="font-normal text-blue-600 text-sm text-right">
        {value}
      </span>
    </div>
  )
}

export default SidebarFeature
