import { FC } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { categoryMap, investments } from '../../../data/marketplace/investments'

const Investments: FC = () => {
  const navigate = useNavigate()
  const data: any = useOutletContext()
  return (
    <div className="flex flex-col">
      <section className="antialiased bg-gray-100 text-gray-600 h-screen px-4">
        <div className="flex flex-col justify-center h-full">
          <div className="w-full mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-3 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800 text-center">
                My Investments
              </h2>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <MyInvestmentsTable
                  userInv={data.investments}
                  kycStatus={data.kyc}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex align-center justify-center">
            <button
              type="button"
              className="bg-indigo-600 px-8 py-2 mt-8 rounded-lg text-gray-100 font-semibold uppercase tracking-wide"
              onClick={() => navigate('/marketplace')}
            >
              Go to Marketplace
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}

const MyInvestmentsTable: FC<any> = ({ userInv, kycStatus }) => {
  const navigate = useNavigate()
  return (
    <table className="table-auto w-full">
      <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
        <tr>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Name</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Category</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Est. Value</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-center">Stake %</div>
          </th>
          <th className="p-1 whitespace-nowrap">
            <div className="font-semibold text-center">Your Investment</div>
          </th>
          <th className="p-1 whitespace-nowrap">
            <div className="font-semibold text-center">Return(3months)</div>
          </th>
          <th className="p-1 whitespace-nowrap">
            <div className="font-semibold text-center">Return(6months)</div>
          </th>
          <th className="p-1 whitespace-nowrap">
            <div className="font-semibold text-center">Return(1 year)</div>
          </th>
          <th className="p-1 whitespace-nowrap">
            <div className="font-semibold text-center">Options</div>
          </th>
        </tr>
      </thead>
      <tbody className="text-sm divide-y divide-gray-100">
        {investments.map(el => {
          const test = userInv.filter((k: any) => k.slug === el.slug)
          return (
            <tr>
              <td className="p-2 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                    <img
                      className="rounded-lg"
                      src={el.images[0]}
                      width="40"
                      height="40"
                      alt={el.title}
                    />
                  </div>
                  <div className="font-medium text-gray-800">{el.title}</div>
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-left">{categoryMap[el.category]}</div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-left font-medium text-green-500">
                  {el.finVolume}
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-base text-center">{el.pofAssets}%</div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-center font-medium text-blue-500">
                  €{test.length > 0 ? test[0].amounth : 0}
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-center font-medium text-green-500">
                  €
                  {test.length > 0
                    ? Math.floor(test[0].amounth * (Number(el.pofAssets) / 100))
                    : 0}
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-center font-medium text-green-500">
                  €
                  {test.length > 0
                    ? Math.floor(
                        test[0].amounth * (Number(el.pofAssets) / 100) * 2,
                      )
                    : 0}
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-center font-medium text-green-500">
                  €
                  {test.length > 0
                    ? Math.floor(
                        test[0].amounth * (Number(el.pofAssets) / 100) * 4,
                      )
                    : 0}
                </div>
              </td>
              <td>
                {test.length <= 0 ? (
                  <button
                    className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-1 rounded-lg w-full focus:outline-none focus:shadow-outline"
                    type="button"
                    disabled={kycStatus === 0}
                    onClick={() => navigate(`/marketplace/${el.slug}/offer`)}
                  >
                    Offer
                  </button>
                ) : (
                  <button
                    className="uppercase text-sm font-bold tracking-wide bg-red-500 text-gray-100 p-1 rounded-lg w-full focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => navigate(`/marketplace/${el.slug}/offer`)}
                  >
                    Leave
                  </button>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Investments
