/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import TeamMembers from './sections/TeamMembers'
import PageHero from '../shared/PageHero'

const About: FC = () => {
  return (
    <section className="text-gray-600 body-font space-y-20 mb-20">
      <PageHero title="Our Team" bgClassName="bg-team">
        <p className="mb-8 leading-relaxed">Meet our team.</p>
      </PageHero>

      <TeamMembers />
    </section>
  )
}

export default About
